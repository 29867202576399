
import {Vue} from "vue-class-component";
import WaterEffectSceneController from "@/components/WaterEffect/classes/WaterEffectSceneController.class";

export default class WaterEffect extends Vue {
  private _sceneController!: WaterEffectSceneController

  public mounted() : void {
    this._sceneController = new WaterEffectSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }

  unmounted() : void {
    this._sceneController.dispose()
  }
}
