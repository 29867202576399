import {SceneItem, SceneItemArgs} from '@/classes/SceneController/abstract/SceneItem.class'
import {
  ArcRotateCamera,
  FreeCamera,
  Mesh,
  MeshBuilder,
  Observer,
  RenderTargetTexture,
  Scene, ShaderMaterial, StandardMaterial,
  TransformNode,
  Vector3
} from '@babylonjs/core'
import InsidePortalScene from "@/components/Portal/classes/portal/InsidePortalScene.class";

export default class PortalSceneItem extends SceneItem {
  private _rootNode!: TransformNode
  private _planeMesh!: Mesh
  private _frameMesh!: Mesh

  private _updateObserver: Observer<Scene> | null

  private _renderTargetTexture!: RenderTargetTexture
  private _camera!: FreeCamera

  private _planeMaterial!: ShaderMaterial

  private _insideScene!: InsidePortalScene

  public get rootNode () : TransformNode {
    return this._rootNode
  }

  constructor(props: SceneItemArgs) {
    super(props)
    this._createPortal()
    this._updateObserver = this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _createPortal() : void {
    this._rootNode = new TransformNode('Portal root node', this.scene)
    this._planeMesh = MeshBuilder.CreatePlane('Portal plane', {
      size: 2
    }, this.scene)

    this._planeMaterial = new ShaderMaterial('Portal plane material', this.scene, "/projects/portal/fx/portal", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      samplers: ['textureSampler'],
      needAlphaBlending: true
    })
    this._planeMesh.material = this._planeMaterial
    this._planeMesh.layerMask = 0x10000000

    this._frameMesh = MeshBuilder.CreateBox('Portal frame', {
      width: 2.2,
      height: 2.2,
      depth: 0.1
    })

    this._frameMesh.layerMask = 0x10000000

    this._renderTargetTexture = new RenderTargetTexture('Portal rtt', {
      width: this._sceneController.engine.getRenderWidth(),
      height: this._sceneController.engine.getRenderHeight(),
    }, this.scene)
    this.scene.customRenderTargets.push(this._renderTargetTexture)

    this._camera = new FreeCamera('Portal camera', Vector3.Zero(), this.scene)
    this._camera.minZ = 0.001
    this._renderTargetTexture.activeCamera = this._camera

    this._planeMaterial.setTexture('textureSampler', this._renderTargetTexture)

    this._camera.parent = this._rootNode
    this._planeMesh.parent = this._rootNode
    this._frameMesh.parent = this._rootNode

    this._frameMesh.position.z += 0.1
  }

  private _update = () : void => {
    //this._planeMesh.rotation.x += 0.01
    //const position = this.scene.activeCamera!.position.clone()
    const delta = this.scene.activeCamera!.position.subtract(this.rootNode.position)
    this._camera.position = delta
    this._camera.setTarget(Vector3.Zero().add(Vector3.Left()).multiply(this.rootNode.position))
    //this._camera.setTarget((this.scene.activeCamera as ArcRotateCamera).target)


    this._camera.layerMask = 0x01000000
    //this._camera.rotation = (this.scene.activeCamera! as ArcRotateCamera).rotation.clone()
    //this._camera.rotation.y += 0.1
  }

  public setPortalScene(insideScene: InsidePortalScene) : void {
    insideScene.rootMesh.getChildMeshes<Mesh>().forEach((meshItem) => {
      meshItem.layerMask = 0x01000000
      if (!this._renderTargetTexture.renderList?.includes(meshItem)) {
        this._renderTargetTexture.renderList?.push(meshItem)
      }
    })

    insideScene.rootMesh.parent = this._rootNode
    insideScene.rootMesh.position = Vector3.Zero()


    this._insideScene = insideScene
  }

  updateRenderMeshes() : void {
    this._insideScene.rootMesh.getChildMeshes<Mesh>().forEach((meshItem) => {
      meshItem.layerMask = 0x01000000
      if (!this._renderTargetTexture.renderList?.includes(meshItem)) {
        this._renderTargetTexture.renderList?.push(meshItem)
      }
    })
  }

  public loadModel(url : string) : void {
    this._insideScene?.loadModelFromUrl(url)
  }

  public dispose () : void {
    if (this._updateObserver) this.scene.onBeforeRenderObservable.remove(this._updateObserver)
    this._insideScene.dispose()
    this._planeMesh.dispose()
    this._rootNode.dispose()
  }
}
