export default class MusicPlayer {
  private _playList: string[] = [
    '/projects/sdf-cube/music/4.mp3',
    '/projects/sdf-cube/music/1.mp3',
    '/projects/sdf-cube/music/2.mp3',
    '/projects/sdf-cube/music/3.mp3'
  ]

  private _audioContext!: AudioContext
  private _audioAnalyzer!: AnalyserNode
  private _gainNode!: GainNode

  private _playStarted = false
  private _muted = false

  private _toVolume = 0

  constructor(private _tracks: HTMLAudioElement[] = [],
              private _currentTrack = 0) {

    setInterval(() => {
      if (this._gainNode) {
        this._gainNode.gain.value += (this._toVolume - this._gainNode.gain.value) / 100
      }
    }, 1)
  }

  private _createTracks() {
    this._playList.forEach((url) => {
      const audio = new Audio(url)
      audio.autoplay = false
      audio.addEventListener('ended', this._onTrackEnded)
      this._tracks.push(audio)

      const source = this._audioContext.createMediaElementSource(audio)
      source.connect(this._gainNode)

      this._gainNode.connect(this._audioAnalyzer)
      this._audioAnalyzer.connect(this._audioContext.destination)

    })
  }

  private _onTrackEnded = (e:Event) => {
    this.playNext()
  }

  public playNext() : void {
    if (this._playStarted && !this._muted) {

      const audioElement = this._tracks[this._currentTrack]
      audioElement.pause()
      audioElement.currentTime = 0

      this._currentTrack++

      if (this._currentTrack >= this._tracks.length) {
        this._currentTrack = 0
      }

      const track = this._tracks[this._currentTrack]

      track.play()
    }
  }

  public toogleMusic() : void {
    if (!this._audioContext) {
      this._audioContext = new AudioContext()
      this._audioAnalyzer = this._audioContext.createAnalyser()
      this._audioAnalyzer.fftSize = 256
      this._gainNode = this._audioContext.createGain()
    }

    if (!this._playStarted) {
      this._createTracks()

      if (this._tracks.length > 0) {
        const firstTrack: HTMLAudioElement = this._tracks[0]

        this._playStarted = true
        this._toVolume = 1

        firstTrack.play()

        // setInterval(() => {
        //   firstTrack.pause()
        //   this._playNext()
        // }, 1000)
      }
    } else {
      this.mute()
    }
  }

  public mute() {
    this._muted = !this._muted
    this._toVolume = this._muted ? 0 : 1
  }

  public getFft(): Uint8Array | null {
    if (this._audioAnalyzer) {
      const bufferLength = this._audioAnalyzer.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength);

      this._audioAnalyzer.getByteTimeDomainData(dataArray)

      return dataArray
    }

    return null
  }

}
