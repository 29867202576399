import SceneController from "@/classes/SceneController.class";
import {PostProcess, Vector2} from "@babylonjs/core";

export default class MorphingManScene extends SceneController {

  private _postProcess!: PostProcess
  private _time = 0
  private _morphIntensity = 0
  private _mousePosition = Vector2.Zero()

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()
    this._createPostProcess()

    this.scene.onBeforeRenderObservable.add(this._update)
    document.addEventListener('mousemove', this._onMouseMove)
  }

  private _update = () => {
    this._time += this.engine.getDeltaTime()
  }

  private _onMouseMove = (e:MouseEvent) => {
    this._mousePosition.x = e.clientX
    this._mousePosition.y = e.clientY
  }

  private _createPostProcess() : void {
    this._postProcess = new PostProcess(
      'occlusion',
      '/projects/morphing-man/fx/morphing-man',
      ['time', 'morphIntensity', 'screenSize', 'mouse'],
      [],
      1.,
      this.cameraController.camera
    )

    this._postProcess.onApply = (effect) => {
      effect.setVector2('screenSize', new Vector2(this._postProcess.width, this._postProcess.height))
      effect.setVector2('mouse', this._mousePosition)
      effect.setFloat('time', this._time)
      effect.setFloat('morphIntensity', this._morphIntensity)
    }
  }

  public setMorphIntensity(val: number) : void {
    this._morphIntensity = val
  }

  dispose() {
    super.dispose();
    this._postProcess.dispose()
  }

}
