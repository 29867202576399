
import { defineComponent, onMounted, onUnmounted, ref} from 'vue'
import ArMarkerSceneController from '@/components/MarkerLogo/classes/ArMarkerSceneController.class'
import {Color3, MeshBuilder, StandardMaterial, Texture, Vector3} from '@babylonjs/core'
import ArMarketPortalController from "@/components/MarkerPortal/classes/ArMarketPortalController.class";

export default defineComponent({
  name: 'MarkerPortal',
  setup() {
    let video = ref<HTMLVideoElement | null>(null)
    let canvas = ref<HTMLCanvasElement | null>(null)
    let sceneController!: ArMarkerSceneController
    let portalController: ArMarketPortalController

    const updateViewSize = () : void => {
      sceneController.setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    onMounted(() => {
      sceneController = new ArMarkerSceneController({
        video: video.value!,
        canvas: canvas.value!
      })


      const plane = MeshBuilder.CreatePlane('portal-plane', {
        size: 50
      }, sceneController.scene)

      sceneController.setModel(plane)
      plane.rotation.x = Math.PI
      plane.position = new Vector3(24, 22, 0)
      plane.layerMask = 0x10000000

      portalController = new ArMarketPortalController(sceneController, plane)

      const backgroundPlane = MeshBuilder.CreatePlane('background plane', {
        size: 300
      }, sceneController.scene)

      const texture = new Texture('/projects/marker-portal/grid.png', sceneController.scene)
      texture.uScale = 3
      texture.vScale = 3

      const mat = new StandardMaterial('bg mat', sceneController.scene)
      mat.diffuseTexture = texture
      mat.disableLighting = true
      mat.emissiveColor = Color3.White()

      backgroundPlane.material = mat

      sceneController.setModel(backgroundPlane)
      backgroundPlane.position = new Vector3(24, 22, -40)
      backgroundPlane.rotation.x = Math.PI

      sceneController.loadModel('/projects/marker-logo/outline-logo.glb').then((mesh) => {
        mesh!.position = new Vector3(20, 10, -20)
        mesh!.scaling = new Vector3(30, 30, 30)
      })

      sceneController.loadMarker('/projects/marker-logo/marker-with-text')

      // let pos = 0
      // sceneController.scene.onBeforeRenderObservable.add(() => {
      //   texture.uOffset = -Math.sin(pos)
      //   texture.vOffset = -Math.cos(pos)
      //   pos += 0.01
      // })

      updateViewSize()

      window.addEventListener('resize', updateViewSize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateViewSize)
      sceneController.dispose()
    })

    return {
      video,
      canvas
    }
  }
})
