
import TourSceneController from "@/components/PanoramaTour/classes/TourSceneController.class"
import {Options, Vue} from "vue-class-component"
import {Vector3} from "@babylonjs/core";
// import RequestAccelerometer from "@/components/PanoramaTour/RequestAccelerometer.vue";
import { reactive } from "vue";

interface RoomModel {
  cubeId: string,
  modelUrl: string,
  position: Vector3
}

interface CubeMapData {
  name: string
  title: string
  points: []
  initialPointId: string,
  initRotation: number,
  roomModels: RoomModel[]
}

@Options({
  components: {
    // RequestAccelerometer
  }
})
export default class PanoramaTour extends Vue {
  private activeCubeId!: string | null
  private sceneController!: TourSceneController
  public loading = true
  public firstTime = true

  public state: {
    sceneController: null | TourSceneController
  } = reactive({
    sceneController: null
  })

  get cubeMapData() : CubeMapData {
    return {
      name: 'room1_custom',
      title: '3к квартира 1',
      points: [],
      initialPointId: 'CubeMap_01',
      initRotation: 0,
      roomModels: []
    }
  }

  mounted() : void {
    this.createSceneController()
  }

  unmounted () : void {
    this.destroySceneController()
  }

  onDeviceOrientationPermissionGranted(granted : boolean) : void {
    if (granted) {
      this.createSceneController(granted)
    }
  }

  private createSceneController(withDeviceOrientation = false): void {
    const data : CubeMapData = this.cubeMapData

    this.sceneController = new TourSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement,
      settings: {
        cubeSize: 3.6,
        cameraAcceleration: 0.001,
        useDeviceOrientation: withDeviceOrientation
      }
    })

    this.sceneController.addEventListener(TourSceneController.Events.loadStarted, this.onLoadStarted)
    this.sceneController.addEventListener(TourSceneController.Events.loadComplete, this.onLoadComplete)

    this.sceneController.createTour({
      modelName: data.name,
      cameraRotation: data.initRotation,
      startFromCubeWithId: data.initialPointId ? data.initialPointId : '',
      roomModels: data.roomModels as []
      // locationTags: this.cubeMapData.locationPoints
    })

    this.state.sceneController = this.sceneController
  }

  // public onNavigationSelected(data : unknown) : void {
  //   this.firstTime = true
  //   this.sceneController.gotoCubeWithId(data.id, data.rotation)
  // }

  public onStartScanClick () : void {
    this.sceneController.startScan()
  }

  public onLoadStarted() : void {
    this.loading = true
  }

  public onLoadComplete() : void {
    this.activeCubeId = this.sceneController.activeCube.id
    this.loading = false
    this.firstTime = false
    // this.sceneController.gotoCubeWithId(this.activeCubeId, 0)
    // console.log('complete')
  }

  public destroySceneController() : void {
    if (this.sceneController) {
      this.sceneController.removeEventListener(TourSceneController.Events.loadStarted, this.onLoadStarted)
      this.sceneController.removeEventListener(TourSceneController.Events.loadComplete, this.onLoadComplete)
      this.sceneController.dispose()
    }
  }
}
