import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "depth-map" }
const _hoisted_2 = { class: "canvas-wrapper" }
const _hoisted_3 = { ref: "imageCanvas" }
const _hoisted_4 = { class: "canvas-wrapper" }
const _hoisted_5 = { ref: "depthMapCanvas" }
const _hoisted_6 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("canvas", _hoisted_5, null, 512)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onNextClick && _ctx.onNextClick(...args)))
      }, "Next image")
    ])
  ]))
}