import MarkerVideo, {MarkerVideoEvents} from "@/components/MarkerLogo/classes/MarkerVideo.class";
import SceneController from "@/classes/SceneController.class";
import {
  AbstractMesh,
  Color4,
  HemisphericLight,
  Mesh,
  MeshBuilder,
  UniversalCamera,
  Vector3
} from "@babylonjs/core";
import ModelController from "@/classes/SceneController/ModelController.class";

declare const window: any

export enum ArMarkerLogoSceneEvents {
  MarkerFounded = 'MarkerFounded',
  MarkerLost = 'MarkerLost',
  MarkerLoaded = 'MarkerLoaded'
}

export default class ArMarkerSceneController extends SceneController {
  private _markerVideo: MarkerVideo
  private _camera!: UniversalCamera

  private _markerRootMesh!: Mesh
  private _logoModel: ModelController

  public get camera() : UniversalCamera {
    return this._camera
  }

  public get markerMesh(): Mesh {
    return this._markerRootMesh
  }

  constructor({ canvas, video, positionFactor, rotationFactor } : {
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    positionFactor?: number,
    rotationFactor?: number
  }) {
    super({
      canvas,
      updateOnWindowResize: false
    })

    this.scene.clearColor = new Color4(0,0,0,0.1)
    this.scene.useRightHandedSystem = true
    this._createCamera()

    this._markerVideo = new MarkerVideo({
      video,
      controller: this,
      positionFactor,
      rotationFactor
    })

    this._markerVideo.addEventListener(MarkerVideoEvents.MarkerLoaded, this._onMarkerLoaded)
    this._markerVideo.addEventListener(MarkerVideoEvents.MarkerFounded, this._onMarkerFounded)
    this._markerVideo.addEventListener(MarkerVideoEvents.MarkerLost, this._onMarkerLost)

    this._logoModel = new ModelController({
      sceneController: this
    })

    this._createMarkerRootMesh()
  }

  private _onMarkerLoaded = () : void => {
    this.dispatchEvent(new Event(ArMarkerLogoSceneEvents.MarkerLoaded))
  }

  private _onMarkerFounded = () : void => {
    this.dispatchEvent(new Event(ArMarkerLogoSceneEvents.MarkerFounded))
  }

  private _onMarkerLost = () : void => {
    this.dispatchEvent(new Event(ArMarkerLogoSceneEvents.MarkerLost))
  }

  private _createCamera() : void {
    this._camera = new UniversalCamera('camera1', new Vector3(0, 0, 0), this.scene)
    this._camera.setTarget(new Vector3(0,0,-1000))
    this._camera.maxZ = 100000
    //const oneRadian = Math.PI / 360
    //this._camera.fov = oneRadian * 60
  }

  private _createMarkerRootMesh() : void {
    this._markerRootMesh = new Mesh('test', this.scene)
    this._markerRootMesh.position = Vector3.Zero()
    this._markerRootMesh.scaling = Vector3.Zero()

    const light = new HemisphericLight('light', new Vector3(0,0,1), this.scene)
    light.intensity = 1
  }

  public async loadModel(url: string, {scaling} : {scaling?: Vector3} = {scaling: undefined}) : Promise<Mesh> {
    await this._logoModel.loadModelFromUrl(url)
    this._logoModel.mesh!.parent = this._markerRootMesh

    const box = MeshBuilder.CreateBox('test', {
      size: 10
    }, this.scene)

    box.parent = this._markerRootMesh
    box.position = new Vector3(22, 24, 5)
    box.scaling = Vector3.Zero()

    return this._logoModel.mesh!
  }

  public setModel(mesh: AbstractMesh) : void {
    mesh.parent = this._markerRootMesh
  }

  public loadMarker(markerUrl: string) : void {
    this._markerVideo.loadMarker(markerUrl)
  }

  public setSize(size : {width: number, height: number}) : void {
    this._markerVideo.setViewSize(size)
  }

  public dispose() : void {
    this._markerVideo.removeEventListener(MarkerVideoEvents.MarkerLoaded, this._onMarkerLoaded)
    this._markerVideo.removeEventListener(MarkerVideoEvents.MarkerFounded, this._onMarkerFounded)
    this._markerVideo.removeEventListener(MarkerVideoEvents.MarkerLost, this._onMarkerLost)
    this._markerVideo.dispose()
  }
}
