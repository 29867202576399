
import {Vue} from "vue-class-component";
import TexturedCubeSceneController from "@/components/SdfTexturedCube/classes/TexturedCubeSceneController.class";
import { reactive } from "vue";

export default class SdfTexturedCube extends Vue {
  private state = reactive({
    buttonEnabled: false
  })

  private _sceneController!: TexturedCubeSceneController

  mounted() : void {
    this._sceneController = new TexturedCubeSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }

  unmounted() : void {
    this._sceneController.dispose()
  }

  public onPlayClick() : void {
    this._sceneController.toggleMusic()
    this.state.buttonEnabled = !this.state.buttonEnabled
  }
}
