
import {Vue} from "vue-class-component";
import VrSceneController from "@/components/Vr/classes/VrSceneController.class";

export default class Vr extends Vue {
  private _sceneController!:VrSceneController

  public mounted() : void {
    this._sceneController = new VrSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }

  public unmounted() : void {
    this._sceneController.dispose()
  }
}
