import SceneController from '@/classes/SceneController.class'
import PortalSceneItem from "@/components/Portal/classes/PortalSceneItem.class";
import InsidePortalScene from "@/components/Portal/classes/portal/InsidePortalScene.class";
import {Color4, CubeTexture, PointLight, Vector3} from "@babylonjs/core";

export default class PortalSceneController extends SceneController {
  private portals: PortalSceneItem[] = []
  private light!: PointLight

  constructor({ canvas } : { canvas : HTMLCanvasElement }) {
    super({ canvas })
    this.createCameraController()
    this.cameraController.camera.layerMask = 0x10000000
    this.scene.clearColor = new Color4(0.5, 0.5, 0.5, .1)

    this._initScene()

    // const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/projects/about-immercyb/env/autoshop.env", this.scene)
    // this.scene.environmentTexture = hdrTexture

    // this.light = new PointLight('Test', new Vector3(10, 120, 51), this.scene)

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    this.portals[2].rootNode.rotation.y += 0.003
    this.portals[2].rootNode.rotation.x += 0.002
  }

  private _initScene () : void {
    this._createPortals()
  }

  private _createPortals() : void {
    const portal = new PortalSceneItem({
      sceneController: this
    })
    const portalScene = new InsidePortalScene(portal)

    const portal2 = new PortalSceneItem({
      sceneController: this
    })
    const portalScene2 = new InsidePortalScene(portal2)

    portal2.rootNode.position.x = 3
    portal2.loadModel('/projects/portal/models/logo.glb')

    const portal3 = new PortalSceneItem({
      sceneController: this
    })
    const portalScene3 = new InsidePortalScene(portal3)

    portal3.rootNode.position.x = -3
    portal3.rootNode.position.y = 0.5
    portal3.rootNode.rotation.y = -0.3

    // portal.rootNode.position.x = 3

    this.portals.push(portal)
    this.portals.push(portal2)
    this.portals.push(portal3)
  }

  public dispose() {
    this.portals.forEach((portalItem) => portalItem.dispose())
    super.dispose()
  }
}
