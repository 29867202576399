import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {PostProcess, Texture, Vector2} from "@babylonjs/core";

export default class ImageMask extends SceneItem {
  private _postProcess: PostProcess
  private _time = 0
  private _screenSize = Vector2.Zero()
  private _mousePosition = Vector2.Zero()

  constructor(props: SceneItemArgs) {
    super(props)

    const tourTexture = new Texture('/projects/image-effects/textures/tour.png')

    this._postProcess = new PostProcess(
      'image-mask',
      '/projects/image-effects/fx/image-mask',
      ['time', 'screenSize', 'mouse'],
      [],
      1.,
      this.camera
    )

    this._postProcess.onApply = (effect) => {
      this._screenSize.x = this._postProcess.width
      this._screenSize.y = this._postProcess.height

      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', this._screenSize)
      effect.setVector2('mouse', this._mousePosition)
      effect.setTexture('textureSampler', tourTexture)
    }

    this.scene.onBeforeRenderObservable.add(() => {
      this._time += this.sceneController.engine.getDeltaTime()
    })

    window.addEventListener('mousemove', this._onMouseMove)
  }

  private _onMouseMove = (e:MouseEvent) => {
    this._mousePosition.x = e.clientX / window.innerWidth
    this._mousePosition.y = e.clientY / window.innerHeight
  }

  public dispose() : void {
    window.removeEventListener('mousemove', this._onMouseMove)
  }
}
