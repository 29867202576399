import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "canvas-wrapper" }
const _hoisted_2 = { ref: "canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_accelerometer = _resolveComponent("request-accelerometer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["default-page tour-application", {visible: !_ctx.firstTime}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("canvas", _hoisted_2, null, 512)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["loading-indicator", {visible: _ctx.loading && !_ctx.firstTime}])
    }, null, 2),
    _createVNode(_component_request_accelerometer, { onGranted: _ctx.onDeviceOrientationPermissionGranted }, null, 8, ["onGranted"])
  ], 2))
}