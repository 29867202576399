import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "default-page" }
const _hoisted_2 = { class: "temp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_peer_video = _resolveComponent("peer-video")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.remotePeers.length), 1),
    _createVNode(_component_peer_video, {
      local: true,
      socket: _ctx.socketClient,
      onLocalMediaStream: _ctx.onLocalMediaStream
    }, null, 8, ["socket", "onLocalMediaStream"]),
    (_ctx.mediaStream)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.remotePeers, (peer, index) => {
          return (_openBlock(), _createBlock(_component_peer_video, {
            key: peer.id,
            local: false,
            socket: _ctx.socketClient,
            requestCall: _ctx.called,
            "local-stream": _ctx.mediaStream,
            "offer-data": {clientId: peer.id, offer: peer.offer, answer: peer.answer}
          }, null, 8, ["socket", "requestCall", "local-stream", "offer-data"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.remotePeers.length > 0 && !_ctx.called && _ctx.mediaStream)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCallClick && _ctx.onCallClick(...args)))
        }, "Позвонить"))
      : _createCommentVNode("", true)
  ]))
}