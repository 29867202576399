
import { defineComponent, ref, Ref, onMounted, onUnmounted } from 'vue'
import DepthMaps from '@/components/DepthMap/classes/DepthMaps.class'
import DepthMapSceneController from "@/components/DepthMap/classes/DepthMapSceneController.class";
import {Vector3} from "@babylonjs/core";

export default defineComponent({
  name: 'DepthMap',
  setup () {
    let depthMapLoadEnabled = true
    let loadTimeout = 0

    const currentIndex = ref(Math.round(Math.random() * 100 + 50))
    const imageCanvas: Ref<HTMLCanvasElement | null> = ref(null)
    const depthMapCanvas: Ref<HTMLCanvasElement | null> = ref(null)

    let sceneController : DepthMapSceneController | null

    onMounted(() => {
      DepthMaps.loadFilesList().then((filesList) => {
        loadDepthMap()
        sceneController = new DepthMapSceneController({
          canvas: depthMapCanvas.value!
        })
      })
    })

    onUnmounted(() => {
      depthMapLoadEnabled = false
      sceneController?.dispose()
    })

    const onNextClick = () => {
      loadDepthMap()
    }

    const loadDepthMap = async () => {
      clearTimeout(loadTimeout)

      if (currentIndex.value >= DepthMaps.ITEMS_COUNT) {
        currentIndex.value = 0
      }

      const imageData : {image: HTMLImageElement | null, x: number, y: number} = await DepthMaps.getMap(currentIndex.value)
      drawImage(imageData)

      // loadTimeout = setTimeout(() => {
      //   if (imageData) {
      //     if (depthMapLoadEnabled) setTimeout(() => {
      //       loadDepthMap()
      //     }, 1)
      //   }
      // })

      currentIndex.value ++
    }

    const drawImage = ({ image, x, y } : {image: HTMLImageElement | null, x: number, y: number}) => {
      const canvas = imageCanvas.value
      if (canvas !== null) {
        const rect = canvas.getBoundingClientRect()
        const context = canvas.getContext('2d')

        const [imageWidth, imageHeight] = [1280, 960]

        canvas.width = rect.width
        canvas.height = rect.height

        let ratio = rect.width / rect.height
        let imageRatio = imageWidth > imageHeight ? rect.width / imageWidth : rect.height / imageHeight

        const toWidth = imageWidth * imageRatio
        const toHeight = imageHeight * imageRatio

        const toY = Math.abs(toHeight - rect.height) / 2
        const toX = Math.abs(toWidth - rect.width) / 2

        context!.drawImage(image!, toX,toY, toWidth, toHeight)

        const points = readPoints(toX, toY, toWidth, toHeight)
        sceneController!.setDepthPoints(points, x, y)
      }
    }

    const readPoints = (fromx : number, fromy : number, width : number, height : number) : Vector3 [] => {
      const canvas = imageCanvas.value
      const points = []

      if (canvas !== null) {
        const rect = canvas.getBoundingClientRect()
        const context = canvas.getContext('2d')
        const step = 20

        for (let x = fromx; x < width + fromx; x += step) {
          for (let y = fromy + 1; y < fromy + height; y += step) {
            const data = context!.getImageData(x, y, 1, 1).data

            const [scanX, scanY] = [(x - fromx) / width, (height - (y - fromy)) / height]
            const depth = 2 * ((data[0] + data[1] + data[2]) / 3) / 255

            points.push(
              new Vector3(scanX,  scanY, depth)
            )
          }
        }
      }

      return points
    }

    return {
      imageCanvas,
      depthMapCanvas,
      onNextClick
    }
  }
})
