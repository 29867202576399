export enum SocketClientEvents {
  Hello = 'Hello',
  Close = 'Close',
  CallRequest = 'CallRequest',
  Offer = 'Offer',
  Answer = 'Answer',
  IceCandidate = 'IceCandidate'
}

export enum SocketClientCommands {
  JoinRoom = 'JoinRoom',
  CreateRoom = 'CreateRoom',
  Call = 'Call',
  AcceptCall = 'AcceptCall',
  Ice = 'Ice',
  File = 'File'
}

export enum RoomEvents {
  UsersList = 'Room.UsersList',
  Destroyed = 'Room.Destroyed',
  Created = 'Room.Created',
  JoinFailed = 'Room.JoinFailed'
}

export class SocketEvent extends Event {
  constructor(public readonly event: string, public readonly data: unknown) {
    super(event)
    this.data = data
  }
}

export default class SocketClient extends EventTarget {
  private _socket!: WebSocket

  constructor() {
    super()
    // this._socket = new WebSocket('ws://localhost:9090', 'dk-protocol')
  }

  public connect() : void {
    this._socket = new WebSocket('wss://api.dclown.ru/wss/', 'dk-protocol')
    this._createSocketListeners()
  }

  private _createSocketListeners() : void {
    this._socket.addEventListener('open', this._onSocketOpen)
    this._socket.addEventListener('close', this._onSocketClose)
    this._socket.addEventListener('message', this._onSocketMessage)
  }

  private _removeSocketListeners () : void {
    this._socket.removeEventListener('open', this._onSocketOpen)
    this._socket.removeEventListener('close', this._onSocketClose)
    this._socket.removeEventListener('message', this._onSocketMessage)
  }

  private _onSocketOpen = () : void => {
    //
  }

  private _onSocketClose = () : void => {
    //
  }

  private _onSocketMessage = (e: MessageEvent) : void => {
    // console.log('Message:', e.data)
    try {
      const event = JSON.parse(e.data) as { event: string, data: unknown }
      this.dispatchEvent(new SocketEvent(event.event, event.data))
    } catch (e) {
      console.log('JSON error', e.stack)
    }
  }

  public joinInRoom(id: string) : void {
    this.sendCommand(SocketClientCommands.JoinRoom, {
      id
    })
  }

  public sendCommand(command: string, data: unknown) : void {
    try {
      this._socket.send(JSON.stringify({
        command,
        data
      }))
    } catch {
      console.log('Error on JSON parse')
    }
  }

  public dispose () : void {
    this._removeSocketListeners()
  }
}
