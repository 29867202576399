import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Color3, FxaaPostProcess, PostProcess, Vector2, Vector3} from "@babylonjs/core";

export default class LavaWorldSceneItem extends SceneItem {
  private _time = 0
  private _postProcess!: PostProcess;
  private _fxaaPostProcess!: FxaaPostProcess;
  private _mouseVector: Vector2 = Vector2.Zero()

  private _color: Color3 = Color3.Blue()

  constructor(props: SceneItemArgs) {
    super(props)
    this._createEffect()

    this.scene.onBeforeRenderObservable.add(this._update)
    window.addEventListener('mousemove', this._onMouseMove)
  }

  public setOptions({color}: {color: Color3}) : void {
    this._color = color
  }

  private _setColor(color: Color3) : void {
    this._color = color
  }

  private _onMouseMove = (e : MouseEvent) => {
    // this._mouseVector.x = e.clientX
    // this._mouseVector.y = e.clientY
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
  }

  private _createEffect() {
    const lavaWorldPostProcess = new PostProcess(
      'occlusion',
      '/projects/index/fx/lava',
      ['time', 'mouse', 'color', 'screenSize'],
      [],
      1.,
      this.camera
    )

    lavaWorldPostProcess.onApply = (effect) => {
      effect.setFloat('time', this._time)
      effect.setVector3('color', new Vector3(this._color.r, this._color.g, this._color.b))
      effect.setVector2('screenSize', new Vector2(lavaWorldPostProcess.width, lavaWorldPostProcess.height))
      effect.setVector2('mouse', this._mouseVector)
    }

    this._postProcess = lavaWorldPostProcess

    this._fxaaPostProcess = new FxaaPostProcess('fxaa', 1., this.camera)

    this._fxaaPostProcess.onApply = (effect) => {
      effect.setTextureFromPostProcessOutput('textureSampler', lavaWorldPostProcess)
    }
  }

  public dispose() : void {
    this._postProcess.dispose(this.camera)
    this._fxaaPostProcess.dispose(this.camera)
    window.removeEventListener('mousemove', this._onMouseMove)
  }
}
