import ArMarkerSceneController from "@/components/MarkerLogo/classes/ArMarkerSceneController.class";
import {
  DepthRenderer, Effect,
  Mesh,
  MeshBuilder, PostProcess, RenderTargetTexture,
  Scene,
  StandardMaterial,
  Texture,
  UniversalCamera,
  Vector3
} from "@babylonjs/core";

export default class ArMarketPortalController {
  private readonly _rendererTarget: RenderTargetTexture
  // private _testPlane: Mesh
  // private _testMaterial: StandardMaterial

  private _postProcess: PostProcess

  private get scene() : Scene {
    return this._sceneController.scene
  }

  constructor(private readonly _sceneController: ArMarkerSceneController, plane: Mesh) {

    this._rendererTarget = new RenderTargetTexture('rtt', 1024, this.scene)
    this.scene.customRenderTargets.push(this._rendererTarget)
    this._rendererTarget.renderList!.push(plane)

    // this._testPlane = MeshBuilder.CreatePlane('test', {
    //   size: 10
    // }, this.scene)

    // this._testPlane.position = new Vector3(0,0, -100)
    // this._testPlane.rotation.x = Math.PI
    //
    // this._testMaterial = new StandardMaterial('test', this.scene)
    // this._testPlane.material = this._testMaterial

    this._postProcess = new PostProcess(
      'portal-marker',
      '/projects/marker-portal/fx/marker-portal',
      ['depthMap'],
      ['depthMap'],
      1,
      this._sceneController.camera
    )

    this._postProcess.onApply = (effect: Effect) : void => {
      effect.setTexture('depthMap', this._rendererTarget)
    }

    // this.scene.onBeforeRenderObservable.add(this._update)
  }

  // private _update = () : void => {
  //   this._testMaterial.diffuseTexture = this._rendererTarget
  // }
}
