import SceneController from "@/classes/SceneController.class";
import FirePostProcess from "@/components/Fire/classes/postProcess/FirePostProcess";
import {Color3, Color4, StandardMaterial, Vector3} from "@babylonjs/core";
import SkullSceneItem from "@/components/Fire/classes/sceneItems/SkullSceneItem.class";
import CubeSceneItem from "@/components/Fire/classes/sceneItems/CubeSceneItem.class";

export default class FireSceneController extends SceneController {
  private _firePostProcess!: FirePostProcess
  private _skullModel!: SkullSceneItem
  private _cubeSceneItem!: CubeSceneItem

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()

    this.scene.clearColor = new Color4(0.3,0.1,0.1,0)


    this.scene.onBeforeRenderObservable.add(() => {
      //this.cameraController.camera.alpha += 0.01
    })

    this._createPostProcess()
    this._createBox()
  }

  private _createPostProcess() : void {
    this._firePostProcess = new FirePostProcess({
      sceneController: this
    })
  }

  private _createBox() : void {
    this._cubeSceneItem = new CubeSceneItem({
      sceneController: this
    })
  }

  private _createSkull() : void {
    this._skullModel = new SkullSceneItem({
      sceneController: this
    })

    this._skullModel.loadModel().then((assetContainer) => {
      this._skullModel.mesh!.position = Vector3.Zero()
      this.cameraController.camera.setTarget(this._skullModel.mesh!.getChildMeshes()[0].position.add(new Vector3(0, -10, 0)))

      this._skullModel.mesh!.getChildMeshes().forEach((mesh) => {
        console.log(mesh.material)
      })

      this.cameraController.camera.upperRadiusLimit = 150
      this.cameraController.camera.radius = 150
      this.cameraController.camera.beta = Math.PI / 2// - 0.3
      this.cameraController.camera.alpha = -0.2

      setTimeout(() => {
        (this.scene.materials[0] as StandardMaterial).diffuseColor = Color3.Red()
      }, 100)
    })
  }

  dispose() {
    super.dispose();
    this._firePostProcess.dispose()
  }

  setOptions(state: { speed: number, len: number }) {
    if (this._firePostProcess) this._firePostProcess.setOptions(state)
  }
}
