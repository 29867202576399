import SceneController from "@/classes/SceneController.class";
import ImageMask from "@/components/ImageEffects/classes/postProcess/ImageMask.class";

export default class ImageEffectsSceneController extends SceneController {
  private _imageMask!: ImageMask

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()

    this._createPostProcess()
  }

  _createPostProcess() {
    this._imageMask = new ImageMask({
      sceneController: this
    })
  }

  dispose() {
    super.dispose();
    this._imageMask.dispose()
  }
}
