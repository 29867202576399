import CubeFace from "@/components/PanoramaTour/classes/merger/CubeData/CubeFace.class";

/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeFaceMesh {
  /**
   *
   * @private {CubeFace}
   */
  _cubeFace

  get orientation () {
    return this._cubeFace.orientation
  }

  constructor({cubeFace}) {
    this._cubeFace = cubeFace
  }

  getPolygonPoints(polygon) {
    const data = polygon.toArray()
    const pointsContainer = []

    if (data.length > 0) {
      data.forEach(polygonData => {
        const points = []
        const edges = Array.from(polygonData.edges)

        const findNextEdge = (point) => {
          const list = edges.filter(item => item.start.x === point.x && item.start.y === point.y)
          return list[0]
        }

        const pointExists = (point) => {
          return points.filter(item => item.x === point.x && item.y === point.y).length > 0
        }

        let point = null
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (point === null) {
            point = edges[0].end
            points.push(edges[0].start)
          } else {
            const nextEdge = findNextEdge(point)
            if (nextEdge && !pointExists(nextEdge.start)) {
              points.push(nextEdge.start)
              point = nextEdge.end
            } else {
              break
            }
          }
        }

        pointsContainer.push(points)
      })
    }

    return pointsContainer
  }

  getShape() {
    const polygon = this._cubeFace.polygon
    let pointsContainer = []
    const polygonArray = polygon.toArray()

    if (polygonArray.length > 0) {
      // const halfSize = this._cubeFace.cubeData.size / 2
      const cubePosition = this._cubeFace.cubeData.position

      pointsContainer = this.getPolygonPoints(polygon)//polygonArray[0].vertices

      pointsContainer.forEach(points => {
        points.forEach(point => {
          switch (this.orientation) {
            case CubeFace.Orientations.pz:
            case CubeFace.Orientations.nz: {
              point.x -= cubePosition.x
              point.y -= cubePosition.y
            }
              break

            case CubeFace.Orientations.px:
            case CubeFace.Orientations.nx: {
              point.x -= cubePosition.z
              point.y -= cubePosition.y
            }
              break

            case CubeFace.Orientations.py:
            case CubeFace.Orientations.ny: {
              point.x -= cubePosition.x
              point.y -= cubePosition.z
            }
              break
          }
        })
      })
    }

    return pointsContainer
  }
}

export default CubeFaceMesh
