import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Color4, Mesh, MeshBuilder, ShaderMaterial, Vector3} from "@babylonjs/core";

export default class BlobedSphereModel extends SceneItem {

  private _lightsPositions: Vector3[] = []
  private _lightsColors: Color4[] = []
  private _lightsTargets: Vector3[] = []
  private _lightsForces: number[] = []
  private _lightsSpecularForces: number[] = []
  private _lightsShininess: number[] = []

  private _mesh!:Mesh
  private _materialFront!: ShaderMaterial
  private _materialBack!: ShaderMaterial
  private _meshFront!: Mesh
  private _meshBack!: Mesh

  private _speed = 1.;
  private _radius = 1.;

  private _time = 0

  public get mesh() : Mesh {
    return this._mesh
  }

  constructor(props: SceneItemArgs) {
    super(props)

    this._createMaterial()
    this._createSphere()

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
    if (this._materialFront) {

      this._materialFront.setInt('lightsCount', 2)

      const dir : Vector3 = this.camera.position.subtract(this._meshFront.position).normalize().multiplyByFloats(0.01, 0.01, 0.01)

      this._materialFront.setFloat('time', this._time)
      this._materialFront.setFloat('speed', this._speed)
      this._materialFront.setFloat('radius', this._radius)

      this._materialBack.setFloat('time', this._time)
      this._materialBack.setFloat('speed', this._speed)
      this._materialBack.setFloat('radius', this._radius)

      this._meshBack.position = this._meshFront.position.subtract(dir)

      //this._applyLightsOnMaterial()

    }
  }

  private _createSphere() : void {
    this._mesh = new Mesh('blob root', this.scene)

    this._meshFront = MeshBuilder.CreateSphere('blob', {
      diameter: 1,
      segments: 300,
      sideOrientation: Mesh.FRONTSIDE
    }, this.scene)

    this._meshBack = MeshBuilder.CreateSphere('blob', {
      diameter: 1,
      segments: 300,
      sideOrientation: Mesh.BACKSIDE
    }, this.scene)

    this._meshFront.material = this._materialFront
    this._meshBack.material = this._materialBack

    this._meshFront.parent = this._mesh
    this._meshBack.parent = this._mesh

  }

  private _createMaterial() : void {
    this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/projects/blobed-sphere/fx/blob", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: true,
    })
    this._materialFront.backFaceCulling = true

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/projects/blobed-sphere/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: true,
    })

    this._materialBack.backFaceCulling = true

    this._createLights()
  }

  setSpeed(val: number) {
    this._speed = val
  }

  setRadius(val: number) {
    this._radius = val
  }

  private _createLights() : void {
    this._lightsPositions.push(new Vector3(3, 0, 0))
    this._lightsPositions.push(new Vector3(-2, 3, -4))
    this._lightsPositions.push(new Vector3(-3, 4, 3))
    this._lightsPositions.push(new Vector3(0., 1., -0.))
    //this._lightsPositions.push(new Vector3(-10., 10, -0.))

    this._lightsColors.push(Color4.FromHexString('#ac15ffFF'))
    this._lightsColors.push(Color4.FromHexString('#00fff0FF'))
    this._lightsColors.push(Color4.FromHexString('#2300ffFF'))
    this._lightsColors.push(new Color4(0, 0, 0, 0.))

    this._lightsTargets.push(new Vector3(0, -0.5, 0.5))
    this._lightsTargets.push(new Vector3(1, 3, 3))
    this._lightsTargets.push(new Vector3(2, 1, 0.5))
    this._lightsTargets.push(new Vector3(0, 11, 0))

    this._lightsForces.push(0.2)
    this._lightsForces.push(0.4)
    this._lightsForces.push(0.3)
    this._lightsForces.push(0)

    this._lightsSpecularForces.push(0.4)
    this._lightsSpecularForces.push(0.3)
    this._lightsSpecularForces.push(0.05)
    this._lightsSpecularForces.push(0)

    this._lightsShininess.push(20)
    this._lightsShininess.push(60)
    this._lightsShininess.push(5)
    this._lightsShininess.push(1)

    this._applyLightsOnMaterial()
  }

  private _applyLightsOnMaterial() : void {
    const lightsPositions: number[] = []
    this._lightsPositions.forEach((position) => {
      lightsPositions.push(position.x)
      lightsPositions.push(position.y)
      lightsPositions.push(position.z)
    })

    const lightsTargets: number[] = []
    this._lightsTargets.forEach((position) => {
      lightsTargets.push(position.x)
      lightsTargets.push(position.y)
      lightsTargets.push(position.z)
    })

    const lightsColors: number[] = []
    this._lightsColors.forEach((color) => {
      lightsColors.push(color.r)
      lightsColors.push(color.g)
      lightsColors.push(color.b)
      lightsColors.push(color.a)
    })

    this._materialFront.setArray3('lightsPositions', lightsPositions)
    this._materialFront.setArray3('lightsTargets', lightsTargets)
    this._materialFront.setArray4('lightsColors', lightsColors)
    this._materialFront.setFloats('lightsForces', this._lightsForces)
    this._materialFront.setFloats('lightsSpecularForces', this._lightsSpecularForces)
    this._materialFront.setFloats('lightsShininess', this._lightsShininess)

    this._materialFront.setInt('lightsCount', this._lightsPositions.length)

    this._materialFront.setVector3('cameraPosition', this.camera.position)
  }
}
