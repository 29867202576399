
import {Options, Vue} from "vue-class-component";
import BlobedSceneController from "@/components/BlobedSphere/classes/BlobedSceneController.class";
import VueSlider from "vue-slider-component";
import { ref } from "vue";
import 'vue-slider-component/theme/default.css'

@Options({
  components: {VueSlider},
  watch: {
    speed(val) {
      this.setSpeed(val)
    },

    radius(val) {
      this.setRadius(val)
    },
  }
})

export default class BlobedSphere extends Vue {

  public speed = ref(0.1)
  public radius = ref(0.5)

  public _sceneController!:BlobedSceneController

  public mounted() : void {
    this._sceneController = new BlobedSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
    this._sceneController.setSpeed(0.1)
    this._sceneController.setRadius(0.5)
  }

  public unmounted() : void {
    this._sceneController.dispose()
  }

  public setSpeed(val: number) : void {
    this._sceneController.setSpeed(val)
  }

  public setRadius(val: number) : void {
    this._sceneController.setRadius(val)
  }
}
