import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from "@/components/Index.vue";
import MorphingMan from "@/components/MorphingMan.vue";
// import NeuralPacman from "@/components/NeuralPacman.vue";
import BlobedSphere from "@/components/BlobedSphere.vue";
import Vr from "@/components/Vr.vue";
import WaterEffect from "@/components/WaterEffect.vue";
import ImageEffects from "@/components/ImageEffects.vue";
import Fire from "@/components/Fire.vue";
import SdfTexturedCube from "@/components/SdfTexturedCube.vue";
import MeshedTour from "@/components/MeshedTour.vue";
import Portal from "@/components/Portal.vue";
import PanoramaTour from "@/components/PanoramaTour.vue";
import WebRtcRoom from "@/components/WebRtcRoom.vue";
import DepthMap from "@/components/DepthMap.vue";
import MindAr from "@/components/MindAr.vue";
import MarkerLogo from "@/components/MarkerLogo.vue";
import MarkerPortal from "@/components/MarkerPortal.vue";
import PoseDetection from "@/components/PoseDetection.vue";
import NaggetsAr from "@/components/NaggetsAr.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/morphing-man',
    name: 'Morphing man',
    component: MorphingMan
  },
  // {
  //   path: '/neural-pacman',
  //   name: 'Neural pacman',
  //   component: NeuralPacman
  // },
  {
    path: '/blob',
    name: 'BlobedSphere',
    component: BlobedSphere
  },
  {
    path: '/vr',
    name: 'Vr',
    component: Vr
  },
  {
    path: '/water-effect',
    name: 'Water effect',
    component: WaterEffect
  },
  {
    path: '/image-effects',
    name: 'Image effects',
    component: ImageEffects
  },
  {
    path: '/fire',
    name: 'Fire',
    component: Fire
  },
  {
    path: '/sdf-cube',
    name: 'SdfCube',
    component: SdfTexturedCube
  },
  {
    path: '/tour360',
    name: 'tour360',
    component: MeshedTour
  },
  {
    path: '/panorama-tour',
    name: 'PanoramaTour',
    component: PanoramaTour
  },
  {
    path: '/portals',
    name: 'portals',
    component: Portal
  },
  {
    path: '/web-rtc-room',
    name: 'WebRtcRoom',
    component: WebRtcRoom
  },
  {
    path: '/depth-map',
    name: 'DepthMap',
    component: DepthMap
  },
  // {
  //   path: '/marker-ar',
  //   name: 'MindAr',
  //   component: MindAr
  // },
  {
    path: '/marker-logo',
    name: 'MarkerLogo',
    component: MarkerLogo
  },
  {
    path: '/naggets-ar',
    name: 'NaggetsAr',
    component: NaggetsAr
  },
  {
    path: '/marker-portal',
    name: 'MarkerPortal',
    component: MarkerPortal
  },
  {
    path: '/pose-detection',
    name: 'PoseDetection',
    component: PoseDetection
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
