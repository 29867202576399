import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "canvas-wrapper" }
const _hoisted_2 = { ref: "canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["default-page portal-application", {visible: !_ctx.firstTime}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("canvas", _hoisted_2, null, 512)
    ])
  ], 2))
}