import SceneController from "@/classes/SceneController.class";
import TexturedCubeSceneItem from "@/components/SdfTexturedCube/classes/TexturedCubeSceneItem.class";
import MusicPlayer from "@/components/SdfTexturedCube/classes/MusicPlayer.class";

export default class TexturedCubeSceneController extends SceneController {
  private _cubeSceneItem: TexturedCubeSceneItem
  private _musicPlayer: MusicPlayer

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})

    this.createCameraController()

    this.engine.setHardwareScalingLevel(1)

    this._cubeSceneItem = new TexturedCubeSceneItem({
      sceneController: this
    })

    this._musicPlayer = new MusicPlayer()

    this._cubeSceneItem.setMusicPlayer(this._musicPlayer)

    window.addEventListener('keydown', this._onKeyDown)
  }

  private _onKeyDown = (e: KeyboardEvent) : void => {
    if (e.code === 'Space') this.playNext()
  }

  public toggleMusic() : void {
    this._musicPlayer.toogleMusic()
  }

  public playNext() : void {
    this._musicPlayer.playNext()
  }
}
