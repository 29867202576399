import {SceneItem, SceneItemArgs} from "./abstract/SceneItem.class"
import {AssetContainer, Material, Mesh, SceneLoader} from "@babylonjs/core"
import "@babylonjs/loaders"

class ModelController extends SceneItem {

  private _rootMesh!: Mesh
  private _assetContainer!: AssetContainer

  get assetContainer(): AssetContainer {
    return this._assetContainer
  }

  get materials(): Material[] {
    return this._assetContainer.materials
  }

  get mesh() : Mesh | null {
    return this._rootMesh || null
  }

  set mesh(mesh) {
    this._rootMesh = mesh!
  }

  constructor(args: SceneItemArgs) {
    super(args)
  }

  private _createRootMesh():void {
    if (this._rootMesh) {
      this._rootMesh.dispose()
    }

    this._rootMesh = new Mesh('root-mesh', this.scene)
  }

  public loadModelFromData(data: string): void {
    this._createRootMesh()

    SceneLoader.LoadAssetContainer("", data, this.scene, (container) => {
      container.addAllToScene()
      container.meshes.forEach(mesh => {
        if (mesh.parent === null) mesh.parent = this._rootMesh
      })
    })
  }

  public loadModelFromUrl(url: string, stopAnimations = false): Promise<AssetContainer> {
    return new Promise<AssetContainer>((resolve) => {
      const splitted: string[] = url.split('/')
      const fileName: string = splitted[splitted.length - 1]

      splitted.pop()
      const path: string = splitted.join('/')

      SceneLoader.LoadAssetContainer(`${path}/`, fileName, this.scene, (container) => {
        if (stopAnimations) {
          container.animationGroups.forEach((animationGroup) => {
            animationGroup.stop()
          })
        }
        this._createRootMesh()
        container.addAllToScene()
        container.meshes.forEach(mesh => {
          if (mesh.parent === null) mesh.parent = this._rootMesh
        })

        this._assetContainer = container
        resolve(container)
      })
    })
  }

  public loadAssetContainerFromUrl(url: string): Promise<AssetContainer> {
    return new Promise<AssetContainer>((resolve) => {
      const splitted: string[] = url.split('/')
      const fileName: string = splitted[splitted.length - 1]

      splitted.pop()
      const path: string = splitted.join('/')

      SceneLoader.LoadAssetContainer(`${path}/`, fileName, this.scene, (container) => {
        resolve(container)
      })
    })
  }
}

export default ModelController
