import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Mesh, MeshBuilder, ShaderMaterial, Texture, Vector2} from "@babylonjs/core";

export default class CubeSceneItem extends SceneItem {
  private _mesh: Mesh
  private _material: ShaderMaterial
  private _time = 0


  constructor(props: SceneItemArgs) {
    super(props)

    this._mesh = MeshBuilder.CreateTiledBox('box', {
      tileSize: 2,
      width: 2,
      height: 2,
      depth: 2
    }, this.scene)
    this._mesh.position.y = .3
    // this._mesh = MeshBuilder.CreateSphere('sphere', {
    //   diameter: 2,
    //   segments: 200,
    // }, this.scene)
    // this._mesh.scaling.y = -1

    // this._mesh = MeshBuilder.CreateBox('box', {
    //   size: 2
    // }, this.scene)

    this._material = new ShaderMaterial("box-mat", this.scene, "/projects/fire/fx/fire", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", 'time', 'screenSize', 'fire_speed', 'fire_len'],
      needAlphaBlending: true,
    })

    this._material.setTexture('textureSampler', new Texture('/projects/fire/textures/dog.jpeg', this.scene))

    this._mesh.material = this._material

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()

    this._material.setFloat('time', this._time)
    this._material.setFloat('fire_speed', 2)
    this._material.setFloat('fire_len', 15)
    this._material.setVector2('screenSize', Vector2.One())

    this._mesh.rotation.x += Math.sin(this._time * .0001) * .01
    this._mesh.rotation.y += Math.sin(this._time * 2 * .0001 + 2) * .01
    // this._mesh.rotation.z -= 0.004
  }
}
