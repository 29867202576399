import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {ParticleSystem, Texture, Vector3} from "@babylonjs/core";

export default class CircleParticles extends SceneItem {
  private _particleSystem: ParticleSystem
  constructor(props: SceneItemArgs) {
    super(props)

    this._particleSystem = new ParticleSystem('circles', 5000, this.scene)
    this._particleSystem.particleTexture = new Texture('/projects/blobed-sphere/textures/particle.png', this.scene)
    this._particleSystem.particleTexture.hasAlpha = true

    this._particleSystem.emitter = new Vector3(0, -100, 20)
    this._particleSystem.minEmitBox = new Vector3(-100, 0, 50)
    this._particleSystem.maxEmitBox = new Vector3(100, 0, 200)

    this._particleSystem.minEmitPower = 3
    this._particleSystem.maxEmitPower = 6

    this._particleSystem.minLifeTime = 50;
    this._particleSystem.maxLifeTime = 50;

    this._particleSystem.preWarmCycles = 500
    this._particleSystem.preWarmStepOffset = 100

    this._particleSystem.start()
  }
}
