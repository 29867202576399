/* eslint-disable */
import {Effect, Engine, Ray, Scene, Vector3} from "@babylonjs/core";
import TourCameraController from "./TourCameraController.class";
import TourGlbController from "./TourGlbController.class";
import CubeMerger from "@/components/MeshedTour/classes/merger/CubeMerger.class";
import CubeData from "@/components/MeshedTour/classes/merger/CubeData.class";
import TourCube from "@/components/MeshedTour/classes/TourCube.class";
import TourMouseMarker from "@/components/MeshedTour/classes/TourMouseMarker.class";
import TourLocationTag from "@/components/MeshedTour/classes/TourLocationTag.class";
import TourRoomModel from "@/components/MeshedTour/classes/TourRoomModel.class";
import isMobile from "is-mobile";

class TourOptions {
  modelName
  cameraRotation
  startFromCubeWithId
  locationTags
  roomModels

  constructor({modelName, cameraRotation, startFromCubeWithId, locationTags, roomModels}) {
    this.modelName = modelName
    this.cameraRotation = cameraRotation
    this.startFromCubeWithId = startFromCubeWithId
    this.locationTags = locationTags
    this.roomModels = roomModels
  }
}

class TourSettings {
  cubeSize = 3.6
  cameraAcceleration = 0.01
  useDeviceOrientation = false

  constructor({cubSize, cameraAcceleration, useDeviceOrientation}) {
    this.subeSize = cubSize
    this.cameraAcceleration = cameraAcceleration
    this.useDeviceOrientation = useDeviceOrientation
  }
}

class TourSceneController extends EventTarget {
  static Events = {
    loadStarted: 'loadStarted',
    loadComplete: 'loadComplete',
    locationTagClicked: 'locationTagClicked'
  }

  /**
   * @type {HTMLCanvasElement}
   * @private
   */
  _canvas

  /**
   * @type {Scene}
   * @private
   */
  _scene

  /**
   * @type {Engine}
   * @private
   */
  _engine

  /**
   * @type {CubeMerger}
   * @private
   */
  _cubeMerger

  /**
   * @type {TourRoomModel[]}
   * @private
   */
  _roomModels = []

  /**
   * @return {HTMLCanvasElement}
   */
  get canvas() {
    return this._canvas
  }

  /**
   * @return {Scene}
   */
  get scene() {
    return this._scene
  }

  /**
   * @return {Engine}
   */
  get engine() {
    return this._engine
  }

  /**
   * @return {TourSettings}
   */
  get settings() {
    return this._settings
  }

  /**
   * @return {TourCameraController}
   */
  get cameraController() {
    return this._cameraController
  }

  /**
   * @return {TourCube[]}
   */
  get tourCubes() {
    return this._tourCubesList
  }

  /**
   * @return {TourCube}
   */
  get activeCube() {
    return this._activeCube
  }

  get tourOptions() {
    return this._tourOptions
  }

  /**
   * @type {TourCameraController}
   * @private
   */
  _cameraController

  /**
   * @type {TourGlbController}
   * @private
   */
  _glbController

  /**
   * @type {TourOptions}
   * @private
   */
  _tourOptions

  /**
   * @type TourSettings
   * @private
   */
  _settings

  /**
   * @type {TourCube[]}
   * @private
   */
  _tourCubesList

  /**
   * @type {TourLocationTag[]}
   * @private
   */
  _tourLocationTags

  /**
   * @type {TourMouseMarker}
   * @private
   */
  _mouseMarker

  /**
   * @type {TourCube}
   * @private
   */
  _activeCube

  mouse = {
    down: false,
    moved: false
  }

  /**
   * @type {boolean}
   * @private
   */
  _loading = false

  _renderStarted = false


  constructor({canvas, settings}) {
    super()
    this._canvas = canvas
    this._settings = new TourSettings(settings)
    this._initEngine()
  }

  _initEngine() {
    this._engine = new Engine(this._canvas, true)
    this._engine.setHardwareScalingLevel(1 / window.devicePixelRatio)
    this._scene = new Scene(this._engine)

    this._initShaders()
    this._createCameraController()
    this._startRender()

    this._initMouseEvents()

    window.addEventListener('resize', this._onWindowResize)
  }

  _onWindowResize = () => {
    setTimeout(() => {
      this.engine.resize()
    })
  }

  _initMouseEvents() {

    this._mouseMarker = new TourMouseMarker({
      sceneController: this
    })

    this._scene.onPointerDown = () => {
      this.mouse.down = true
      this.mouse.moved = false
      this._cameraController.cleanRotateY()
    }

    this._scene.onPointerUp = (e, info) => {
      console.log('poiner up', this.mouse.moved)
      if (!this.mouse.moved && !this.cameraController.moving) {
        info.ray.length = 1000
        const pickInfoList = this._scene.multiPickWithRay(info.ray)

        console.log('info', e, pickInfoList)

        /**
         *
         * @type {PickingInfo}
         */
        let selectedPickInfo = null
        let minDistance = null

        pickInfoList.forEach((item) => {
          const distance = Vector3.Distance(this._cameraController.camera.position, item.pickedPoint)
          if (minDistance === null || minDistance > distance) {
            minDistance = distance
            selectedPickInfo = item
          }
        })

        const pickedPoint = selectedPickInfo.pickedPoint

        if (pickedPoint) {
          let minDistanceToCube = null
          let nearCube = null

          this._tourCubesList.forEach(tourCube => {
            const distance = Vector3.Distance(pickedPoint, tourCube.position)

            const rayDirection = this._cameraController.camera.position.subtract(tourCube.position).normalize()
            const rayDistance = Vector3.Distance(this._cameraController.camera.position, tourCube.position)
            const ray = new Ray(tourCube.position, rayDirection, rayDistance)

            const pickedInfoList = this._scene.multiPickWithRay(ray)

            if (pickedInfoList.length === 0 && (minDistanceToCube === null || minDistanceToCube > distance)) {
              minDistanceToCube = distance
              nearCube = tourCube
            }
          })

          if (nearCube) this.gotToCube(nearCube)
        }

        this._tourLocationTags.forEach(locationTagItem => {
          if (locationTagItem.intersectRay(info.ray)) {
            this.dispatchEvent(new CustomEvent(TourSceneController.Events.locationTagClicked, {
              detail: locationTagItem
            }))
          }
        })

      }
      this.mouse.down = false
      this.mouse.moved = false
    }

    this._scene.onPointerMove = (e, info) => {
      if (!isMobile()) {
        this.mouse.moved = true
      }

      if (!this.settings.useDeviceOrientation && e.movementX !== 0 && e.movementY !== 0) {
        info.ray.length = 1000
        this.mouse.moved = true

        const pickInfoList = this._scene.multiPickWithRay(info.ray)
        let selectedPickInfo = null
        let minDistance = null

        pickInfoList.forEach((item) => {
          const distance = Vector3.Distance(this._cameraController.camera.position, item.pickedPoint)
          if (minDistance === null || minDistance > distance) {
            minDistance = distance
            selectedPickInfo = item
          }
        })

        if (selectedPickInfo) this._mouseMarker.setPickInfo(selectedPickInfo)
      }
    }
  }

  _startRender() {
    // this._renderStarted = true
    // this._requestFrame()
    this._engine.runRenderLoop(() => {
      if (this._tourCubesList) this._tourCubesList.forEach(cube => {
        cube.setProgress(this._cameraController.progress)
      })
      this._scene.render()
    })
  }

  _requestFrame() {
    if (this._renderStarted) {
      // console.log(this._cameraController.progress)
      if (this._tourCubesList) this._tourCubesList.forEach(cube => {
        cube.setProgress(this._cameraController.progress)
      })

      this._scene.render()

      if (this._renderStarted) window.requestAnimationFrame(() => {
        this._requestFrame()
      })
    }
  }

  _initShaders() {
    Effect.ShadersStore.transparentSkyBoxVertexShader = "\r\nprecision highp float;" +
      "\r\nattribute vec3 position;" +
      "\r\nuniform mat4 worldViewProjection;" +
      "\r\nvarying vec3 vPosition;" +
      "\r\nvoid main(void) {" +
      "\r\n     vec4 p = vec4( position, 1. );" +
      "\r\n     vPosition = position;" +
      "\r\n     gl_Position =  worldViewProjection * p;" +
      "\r\n" +
      "}" +
      "\r\n"

    Effect.ShadersStore.transparentSkyBoxFragmentShader = "\r\nprecision highp float;" +
      "\r\nvarying vec3 vPosition;" +
      "\r\nuniform samplerCube textureCubeSampler1;" +
      "\r\nuniform samplerCube textureCubeSampler2;" +
      "\r\nuniform mat4 reflectionMatrix1;" +
      "\r\nuniform mat4 reflectionMatrix2;" +
      "\r\nuniform float progress;" +
      "\r\nvoid main(void) {" +
      "\r\n    vec3 coords1 = (reflectionMatrix1 * vec4(vPosition, 1.0)).xyz;" +
      "\r\n    vec3 coords2 = (reflectionMatrix2 * vec4(vPosition, 1.0)).xyz;" +
      "\r\n    vec4 baseColor1 = textureCube(textureCubeSampler1, coords1);" +
      "\r\n    vec4 baseColor2 = textureCube(textureCubeSampler2, coords2);" +
      "\r\n    vec4 result = mix(baseColor1, baseColor2, progress);" +
      "\r\n    gl_FragColor = result;" +
      // "\r\n    gl_FragColor = mix(baseColor1, baseColor2, smoothstep(0., .9, progress));" +
      "\r\n" +
      "}" +
      "\r\n"
  }

  _createCameraController() {
    this._cameraController = new TourCameraController({
      sceneController: this
    })
  }

  /**
   *
   * @param args {{modelName: String, startFromCubeWithId: string, cameraRotation: number, roomModels: []}}
   */
  createTour ({...args}) {
    this._tourOptions = new TourOptions({...args})

    this._glbController = new TourGlbController({
      sceneController: this,
      modelName: this._tourOptions.modelName
    })

    this.cameraController.camera.rotation.y = this._tourOptions.cameraRotation

    this._glbController.addEventListener(TourGlbController.Events.ready, this._onGlbControllerReady)
  }

  _createLocationTags() {
    this._tourLocationTags = []

    if (this._tourOptions.locationTags) {
      this._tourOptions.locationTags
        .filter(tagData => tagData.cubeId)
        .forEach(tagData => {
          const tourLocationTag = new TourLocationTag({
            data: tagData,
            sceneController: this
          })
          this._tourLocationTags.push(tourLocationTag)
        })
    }
  }

  _onGlbControllerReady = () => {
    this._mergeCubes()
    this._loadRoomModels()
    this._createLocationTags()
    this._startView()
  }

  _loadRoomModels() {
    this._tourOptions.roomModels.forEach((roomModelData) => {
      const cube = this.getCubeById(roomModelData.cubeId)

      if (cube) {
        const roomModel = new TourRoomModel({
          cube,
          modelUrl: roomModelData.modelUrl,
          position: roomModelData.position,
          sceneController: this
        })

        this._roomModels.push(roomModel)
      }
    })
  }

  _mergeCubes() {
    const cubesListForMerge = []
    this._glbController.getCubesData().forEach((cubeDataFromGlb) => {
      cubeDataFromGlb.position.y = Math.round(cubeDataFromGlb.position.y * 100) / 100
      cubesListForMerge.push(new CubeData({
        position: cubeDataFromGlb.position,
        size: this._settings.cubeSize,
        id: cubeDataFromGlb.id
      }))
    })

    this._tourCubesList = []
    const cubeMerger = new CubeMerger({cubesData: cubesListForMerge})
    // cubeMerger
    cubeMerger.createCubes()

    const cubesData = this._glbController.getCubesData()

    cubeMerger.cubesData.forEach((cubeData) => {
      const controllerData = cubesData.filter(item => item.id === cubeData.id)[0]
      this._createTourCube({url: controllerData.url, hqUrl: controllerData.hqUrl, cubeData})
    })
  }

  /**
   *
   * @param cubeData {CubeData}
   * @private
   */
  _createTourCube({url, hqUrl, cubeData}) {
    this._tourCubesList.push(new TourCube({
      url,
      hqUrl,
      cubeData,
      sceneController: this
    }))
  }

  _startView() {
    if (this._tourCubesList.length > 0) {
      if (this._tourOptions.startFromCubeWithId) {
        const list = this._tourCubesList.filter(item => item.id === this._tourOptions.startFromCubeWithId)
        if (list.length > 0) {
          this.gotToCube(list[0])
        } else {
          this.gotToCube(this._tourCubesList[0])
        }
      } else {
        this.gotToCube(this._tourCubesList[0])
      }
    }
  }

  /**
   * @param id {String}
   * @return {TourCube|null}
   */
  getCubeById(id) {
    const list = this._tourCubesList.filter(item => item.id === id)
    return list.length > 0 ? list[0] : null
  }

  toggleCubes() {
    const currentCube = this._cameraController.cube
    this.gotToCube(currentCube === this._tourCubesList[1] ? this._tourCubesList[0] : this._tourCubesList[1])

    setTimeout(() => {
      this.toggleCubes()
    }, 7000)
  }

  gotoCubeWithId(id, rotation) {
    if (!this._loading && !this._cameraController.moving) {
      const list = this._tourCubesList.filter(cubeItem => cubeItem.id === id)

      if (list.length > 0) {
        const cube = list[0]
        this.gotToCube(cube, true)
        this._cameraController.rotateY(rotation)
      }
    }
  }

  /**
   * @param cube {TourCube}
   */
  gotToCube(cube, loadAnyWay = false) {
    if (!this._loading && !this._cameraController.moving) {
      this._loading = true
      this.dispatchEvent(new Event(TourSceneController.Events.loadStarted))

      this._activeCube = cube

      this._cameraController.setCube(cube, loadAnyWay).then(() => {
        if (loadAnyWay) {
          this._cameraController.camera.position = cube.position.clone()
        }

        this._loading = false
        this._tourCubesList.forEach(cubeItem => {
          cubeItem.setActiveCube(cube)
        })

        this.dispatchEvent(new Event(TourSceneController.Events.loadComplete))
      })
    }
  }

  dispose() {
    this._renderStarted = false

    if (this._glbController) {
      this._glbController.removeEventListener(TourGlbController.Events.ready, this._onGlbControllerReady)
      this._glbController.dispose()
    }
    this._engine.dispose()
    window.removeEventListener('resize', this._onWindowResize)
  }
}

export default TourSceneController
