
import {Options, Vue} from "vue-class-component";
import MorphingManScene from "@/components/MorphingMan/classes/MorphingManScene.class";
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css'
import { ref } from "vue";

@Options({
  components: {VueSlider},
  watch: {
    morphIntensity(val) {
      this.setMorphIntensity(val / 100)
    }
  }
})
export default class MorphingMan extends Vue {
  private _sceneController!: MorphingManScene

  public morphIntensity = ref(0)

  public mounted() : void {
    this._createSceneController()
  }

  public unmounted() : void {
    this._sceneController.dispose()
  }

  private _createSceneController() : void {
    this._sceneController = new MorphingManScene({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
    //this.morphIntensity = this.morphIntensity.value
    this.setMorphIntensity(0)
  }

  public setMorphIntensity(val: number) : void {
    this._sceneController.setMorphIntensity(val)
  }
}
