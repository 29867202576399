import { Vector3 } from '@babylonjs/core'

export default class DepthMaps {
  public static ITEMS_COUNT = 200
  private static _Images: HTMLImageElement[] = []
  public static _FilesList: string[] = []

  public static async loadFilesList() : Promise<string[]> {
    const response = await fetch('/projects/depth-map/1/files-list.txt')
    const text = await response.text()

    DepthMaps._FilesList = text.split('\n').filter((item) => item.trim())

    return DepthMaps._FilesList
  }

  public static getMap(index: number) : Promise<any> {
    // console.log('getMap', index, DepthMaps._FilesList)
    let image : HTMLImageElement | null = index > DepthMaps._Images.length ? DepthMaps._Images[index] : null
    const [name, xStr, yStr] = DepthMaps._FilesList[index].split('_')
    const [x, y] = [
      parseFloat(xStr.replace('x', '.')),
      parseFloat(yStr.replace('x', '.'))
    ]

    return new Promise<any>((resolve) => {
      if (DepthMaps._Images[index]) {
        resolve(DepthMaps._Images[index])
      } else {
        const url = `/projects/depth-map/1/depth/${DepthMaps._FilesList[index]}`
        image = new Image()
        image.src = url
        image.onload = () => {
          return resolve({
            image,
            x,
            y
          })
        }
        // document.body.append(image)
        DepthMaps._Images[index] = image
      }
    })
  }
}
