
import {Vue} from 'vue-class-component'
import PortalSceneController from '@/components/Portal/classes/PortalSceneController.class'

export default class Portal extends Vue {
  private portalSceneController!: PortalSceneController

  public mounted () : void {
    this.portalSceneController = new PortalSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }

  unmounted () : void {
    this.portalSceneController.dispose()
  }
}
