/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeMerger {

  /**
   *
   * @private {CubeData[]}
   */
  _cubesData

  get cubesData() {
    return this._cubesData
  }

  /**
   *
   * @param cubesData {CubeData[]}
   */
  constructor({cubesData}) {
    this._cubesData = cubesData
  }

  createCubes() {
    this._cubesData.forEach(cubeItem => {
      const intersectedCubes = this._getIntersectedCubes(cubeItem)
      cubeItem.createMesh(intersectedCubes)
    })
  }

  /**
   *
   * @param cube {CubeData}
   * @return {CubeData[]}
   */
  _getIntersectedCubes(cube) {
    const intersectedList = []

    this._cubesData.filter(item => item !== cube).forEach(cubeItem => {
      cube.intersect(cubeItem) ? intersectedList.push(cubeItem) : null
    })

    return intersectedList
  }
}

export default CubeMerger
