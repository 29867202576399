import {SceneItem, SceneItemArgs} from '@/classes/SceneController/abstract/SceneItem.class'
import {
  AssetContainer,
  Color3,
  Mesh,
  MeshBuilder,
  Observer,
  PointLight,
  Scene, SceneLoader,
  StandardMaterial,
  Vector3
} from '@babylonjs/core'
import PortalSceneItem from "@/components/Portal/classes/PortalSceneItem.class";

export default class InsidePortalScene extends SceneItem {
  public _rootMesh!: Mesh
  private _updateObserver: Observer<Scene> | null

  private _testCube!: Mesh
  private _testPointLight!: PointLight

  private _portalSceneItem: PortalSceneItem

  public get rootMesh() : Mesh {
    return this._rootMesh
  }

  constructor(portalSceneItem: PortalSceneItem) {
    super({
      sceneController: portalSceneItem.sceneController
    })

    this._portalSceneItem = portalSceneItem
    this._createScene()
    this._updateObserver = this.scene.onBeforeRenderObservable.add(this._update)

    this._portalSceneItem.setPortalScene(this)
  }

  private _update = () : void => {
    this._testCube.rotation.x += 0.01
    this._testCube.rotation.y += 0.02

    // this._testCube.position.x = 0
    // this._testCube.position.y = 0
    this._testCube.position.z = 2
    //console.log(this._testCube.parent.parent.position)
  }

  private _createScene() : void {
    this._rootMesh = new Mesh('Scene root mesh', this.scene)

    const cube = MeshBuilder.CreateBox('test_box', {
      size: 1.3
    })

    cube.parent = this._rootMesh
    cube.position = cube.position.add(Vector3.Forward())

    const material = new StandardMaterial('test', this.scene)
    material.diffuseColor = Color3.Red()
    //material.emissiveColor = Color3.Red()
    material.disableLighting = false
    cube.material = material

    // cube.position.x = Math.random() * 2
    cube.rotation.x = Math.random() * 10
    this._testCube = cube

    // this._testPointLight = new PointLight('Test', new Vector3(1, 0, 2), this.scene)
    // this._testPointLight.radius = 1
  }

  public loadModelFromUrl(url: string, stopAnimations = false): Promise<AssetContainer> {
    return new Promise<AssetContainer>((resolve) => {
      const splitted: string[] = url.split('/')
      const fileName: string = splitted[splitted.length - 1]

      splitted.pop()
      const path: string = splitted.join('/')

      SceneLoader.LoadAssetContainer(`${path}/`, fileName, this.scene, (container) => {
        if (stopAnimations) {
          container.animationGroups.forEach((animationGroup) => {
            animationGroup.stop()
          })
        }
        container.addAllToScene()
        const assetsMeshContainer = new Mesh('Assets root mesh', this.scene)
        container.meshes.forEach(mesh => {
          if (mesh.parent === null) mesh.parent = assetsMeshContainer
        })

        assetsMeshContainer.parent = this._rootMesh
        assetsMeshContainer.position.z = 1
        assetsMeshContainer.position.x = 0.5
        assetsMeshContainer.position.y = 0
        this._portalSceneItem.updateRenderMeshes()
        this._testCube.dispose()
        resolve(container)
      })
    })
  }

  public dispose() : void {
    if (this._updateObserver) this.scene.onBeforeRenderObservable.remove(this._updateObserver)
    this._rootMesh.dispose()
  }
}
