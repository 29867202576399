
import { defineComponent, ref } from 'vue'
import SocketClient, {
  RoomEvents,
  SocketClientCommands,
  SocketClientEvents,
  SocketEvent
} from '@/components/WebRtcRoom/SocketClient.class'
import PeerVideo from '@/components/WebRtcRoom/PeerVideo.vue'

interface RemotePeer {
  id: number,
  offer?: string
  answer?: string
}

export default defineComponent({
  name: 'WebRtcRoom',
  components: {
    PeerVideo,
  },
  setup ()  {
    let socketClient = new SocketClient()
    const mediaStream = ref<MediaStream | null>(null)
    const myId = ref<number>(0)
    const called = ref(false)

    const remotePeers = ref<RemotePeer[]>([])

    const onLocalMediaStream = (stream: MediaStream) => {
      socketClient.connect()
      socketClient.addEventListener(SocketClientEvents.Hello, onSocketHello)
      socketClient.addEventListener(SocketClientEvents.Offer, onSocketOffer)
      socketClient.addEventListener(SocketClientEvents.Answer, onSocketAnswer)
      socketClient.addEventListener(RoomEvents.UsersList, onSocketRoomUsersList)

      mediaStream.value = stream
    }

    const onSocketOffer = (e : Event) : void => {
      console.log('on offer')
      const data = (e as SocketEvent).data as { offer : string, from: { id : number} }
      remotePeers.value = remotePeers.value.map((peerItem) => {
        return peerItem.id === data.from.id ? {
          ...peerItem,
          offer: data.offer
        } : peerItem
      })
    }

    const onSocketAnswer = (e : Event) : void => {
      console.log('on answer')
      const data = (e as SocketEvent).data as { offer : string, from: { id : number} }

      remotePeers.value = remotePeers.value.map((peerItem) => {
        return peerItem.id === data.from.id ? {
          ...peerItem,
          answer: data.offer
        } : peerItem
      })

      console.log('onSocketAnswer', remotePeers.value)
    }

    const onSocketRoomUsersList = (e: Event) : void => {
      if (myId.value) {
        const data = (e as SocketEvent).data as { room: { id: string }, clients: { id: number }[] }
        remotePeers.value = data.clients
            .filter((clientItem) => myId.value !== clientItem.id)
            .map((clientItem) => {
              return {
                id: clientItem.id
              }
            })

        if (remotePeers.value.length > 0) {
          setTimeout(() => {
            onCallClick()
          }, 1000)
        } else {
          called.value = false
        }
      }
    }

    const onSocketHello = (e:Event) : void => {
      const data = (e as SocketEvent).data as { id : number }
      myId.value = data.id
      socketClient!.joinInRoom("77")
    }

    const onCallClick = () : void => {
      called.value = true
      // socketClient.sendCommand(SocketClientCommands.Call,)
    }

    return {
      socketClient,
      called,
      myId,
      remotePeers,
      mediaStream,
      onLocalMediaStream,
      onCallClick
    }
  }
})
