/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeBoundaries {
  minx = null
  miny = null
  minz = null

  maxx = null
  maxy = null
  maxz = null

  toString() {
    return `minx: ${this.minx}, maxx: ${this.maxx}, miny: ${this.miny}, maxy: ${this.maxy}, minz: ${this.minz}, maxz: ${this.maxz}`
  }
}

export default CubeBoundaries
