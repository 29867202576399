import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  ref: "video",
  autoplay: "true",
  width: "320",
  height: "240",
  style: {backgroundColor: '#ff0000'}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "peer-video",
    style: _normalizeStyle({border: _ctx.local ? 'none' : '2px solid #fff'})
  }, [
    _createElementVNode("video", _hoisted_1, null, 512),
    (_ctx.local && !_ctx.mediaStream)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEnableVideoClick && _ctx.onEnableVideoClick(...args)))
        }, "Включить видео"))
      : _createCommentVNode("", true)
  ], 4))
}