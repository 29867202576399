import SceneController from "@/classes/SceneController.class";
import {Color4, DefaultRenderingPipeline} from "@babylonjs/core";
import BlobedSphereModel from "@/components/BlobedSphere/classes/sceneItems/BlobedSphereModel.class";
import CircleParticles from "@/components/BlobedSphere/classes/sceneItems/CircleParticles.class";

export default class BlobedSceneController extends SceneController {

  private _blobedSphereModel!: BlobedSphereModel
  private _circleParticles!: CircleParticles

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()

    this.cameraController.camera.radius = 2

    this.scene.clearColor = new Color4(.0,.0,0,1)

    this._blobedSphereModel = new BlobedSphereModel({
      sceneController: this
    })

    this._circleParticles = new CircleParticles({
      sceneController: this
    })

    const defaultPipeline = new DefaultRenderingPipeline('def', true, this.scene, [this.cameraController.camera])
    defaultPipeline.fxaaEnabled = true
    defaultPipeline.samples = 10

    defaultPipeline.bloomEnabled = false
    defaultPipeline.bloomThreshold = 0.1;
    defaultPipeline.bloomWeight = 1;
    defaultPipeline.bloomKernel = 32;
    defaultPipeline.bloomScale = 0.5;
  }

  setSpeed(val: number) : void {
    this._blobedSphereModel.setSpeed(val);
  }
  setRadius(val: number) : void {
    this._blobedSphereModel.setRadius(val);
  }
}
