
import {Options, Vue} from "vue-class-component"
import {reactive} from 'vue'
import FireSceneController from "@/components/Fire/classes/FireSceneController.class"
import VueSlider from "vue-slider-component";

@Options({
  components: {VueSlider},

  watch: {
    state: {
      deep: true,
      handler() {
        this.updateOptions()
      }
    }
  }
})
export default class ImageEffects extends Vue {
  private _sceneController!: FireSceneController
  public state = reactive({
    speed: 2.3,
    len: 85.5
  })

  mounted() : void {
    this._sceneController = new FireSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    this._sceneController.setOptions(this.state)
  }

  unmounted() : void {
    this._sceneController.dispose()
  }

  public updateOptions() : void {
    this._sceneController.setOptions(this.state)
  }
}
