import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sdf-cube default-page" }
const _hoisted_2 = { class: "canvas-wrapper" }
const _hoisted_3 = { ref: "canvas" }
const _hoisted_4 = { class: "play-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["play-button", {enabled: _ctx.state.buttonEnabled}]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPlayClick && _ctx.onPlayClick(...args)))
      }, null, 2)
    ])
  ]))
}