import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {AssetContainer} from "@babylonjs/core";

export default class SkullSceneItem extends ModelController {
  constructor(args: SceneItemArgs) {
    super(args)
  }

  public loadModel() : Promise<AssetContainer> {
    return this.loadModelFromUrl('/projects/fire/models/skull.babylon')
  }
}
