
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import PoseDetectionSceneController from "@/components/PoseDetection/classes/PoseDetectionSceneController.class";

export default defineComponent({
  name: 'PoseDetection',
  setup() {
    let sceneController: PoseDetectionSceneController | null = null
    const canvas = ref<HTMLCanvasElement | null>(null)
    const video = ref<HTMLVideoElement | null>(null)

    onMounted(() => {
      sceneController = new PoseDetectionSceneController({
        canvas: canvas.value!,
        video: video.value!
      })
    })

    onUnmounted(() => {
      sceneController?.dispose()
    })

    return {
      canvas,
      video
    }
  }
})
