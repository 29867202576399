import {Vector3} from "@babylonjs/core"
import CubeBoundaries from "./CubeData/CubeBoundaries.class"
import CubeVertexEdges from "./CubeData/CubeVertexEdges.class"
import CubeFace from "./CubeData/CubeFace.class"

/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeData {
  /**
   * @type {String}
   * @private
   */
  _id

  /**
   * @type {Vector3}
   */
  position = Vector3.Zero()

  /**
   * @type int
   */
  size = 3

  /**
   *
   * @private {Vector3[]}
   */
  _vertices

  /**
   * Грани выходящие из каждой вершины
   * @private {CubeVertexEdges[]}
   */
  _vertexEdges

  /**
   * Плоскости куба
   * @private {CubeFace[]}
   */
  _faces

  /**
   *  Границы куба
   * @private {CubeBoundaries}
   */
  _cubeBoundaries

  constructor({position, size, id}) {
    this.position = position
    this.size = size
    this._id = id

    this._createVertices()
  }

  /**
   * @return {String}
   */
  get id () {
    return this._id
  }

  /**
   * @return {Vector3[]}
   */
  get vertices () {
    return this._vertices
  }

  /**
   * @private {CubeVertexEdges[]}
   */
  get vertexEdges() {
    return this._vertexEdges
  }

  /**
   *
   * @return {CubeFace[]}
   */
  get faces() {
    return this._faces
  }

  _createVertices() {
    const halfSize = this.size / 2

    this._vertices = []

    this._vertices[0] = new Vector3(-halfSize, halfSize, -halfSize)
    this._vertices[1] = new Vector3(-halfSize, halfSize, halfSize)
    this._vertices[2] = new Vector3(halfSize, halfSize, halfSize)
    this._vertices[3] = new Vector3(halfSize, halfSize, -halfSize)

    this._vertices[4] = new Vector3(-halfSize, -halfSize, -halfSize)
    this._vertices[5] = new Vector3(-halfSize, -halfSize, halfSize)
    this._vertices[6] = new Vector3(halfSize, -halfSize, halfSize)
    this._vertices[7] = new Vector3(halfSize, -halfSize, -halfSize)

    this._vertices = this._vertices.map((vertex) => vertex.add(this.position))

    this._createBoundaries()
    this._createVertexEdges()
    this._createFaces()
  }

  /**
   * Создание плоскостей куба
   * @private
   */
  _createFaces() {
    const py = new CubeFace({
      cubeData: this,
      orientation: CubeFace.Orientations.py,
      vertices: [
        this._vertices[0],
        this._vertices[1],
        this._vertices[2],
        this._vertices[3]
      ]
    })

    const ny = new CubeFace({
      cubeData: this,
      orientation: CubeFace.Orientations.ny,
      vertices: [
        this._vertices[4],
        this._vertices[5],
        this._vertices[6],
        this._vertices[7]
      ]
    })

    const px = new CubeFace({
      cubeData: this,
      orientation: CubeFace.Orientations.px,
      vertices: [
        this._vertices[7],
        this._vertices[3],
        this._vertices[2],
        this._vertices[6]
      ]
    })

    const nx = new CubeFace({
      cubeData: this,
      orientation: CubeFace.Orientations.nx,
      vertices: [
        this._vertices[4],
        this._vertices[0],
        this._vertices[1],
        this._vertices[5]
      ]
    })

    const pz = new CubeFace({
      cubeData: this,
      orientation: CubeFace.Orientations.pz,
      vertices: [
        this._vertices[5],
        this._vertices[1],
        this._vertices[2],
        this._vertices[6]
      ]
    })

    const nz = new CubeFace({
      cubeData: this,
      orientation: CubeFace.Orientations.nz,
      vertices: [
        this._vertices[4],
        this._vertices[0],
        this._vertices[3],
        this._vertices[7]
      ]
    })

    this._faces = []
    this._faces.push(py)
    this._faces.push(ny)

    this._faces.push(px)
    this._faces.push(nx)

    this._faces.push(pz)
    this._faces.push(nz)
  }

  /**
   * Создание вершины с координатами других близжайших лучей
   * @private
   */
  _createVertexEdges() {
    this._vertexEdges = []

    this._vertices.forEach(vertex => {
      const verticesForEdges = []

      this._vertices.filter(item => item !== vertex).forEach(anotherVertex => {
        if (
          anotherVertex.x === vertex.x && anotherVertex.y === vertex.y && anotherVertex.z !== vertex.z ||
          anotherVertex.x === vertex.x && anotherVertex.y !== vertex.y && anotherVertex.z === vertex.z ||
          anotherVertex.x !== vertex.x && anotherVertex.y === vertex.y && anotherVertex.z === vertex.z
        ) {
          verticesForEdges.push(anotherVertex)
        }
      })

      this._vertexEdges.push(new CubeVertexEdges({
        pivot: vertex,
        vertices: verticesForEdges
      }))
    })
  }

  /**
   * Рассчет границ куба
   * @private
   */
  _createBoundaries() {
    this._cubeBoundaries = new CubeBoundaries()
    this._vertices.forEach(vertex => {
      if (this._cubeBoundaries.minx === null || this._cubeBoundaries.minx > vertex.x) {
        this._cubeBoundaries.minx = vertex.x
      }

      if (this._cubeBoundaries.maxx === null || this._cubeBoundaries.maxx < vertex.x) {
        this._cubeBoundaries.maxx = vertex.x
      }

      if (this._cubeBoundaries.miny === null || this._cubeBoundaries.miny > vertex.y) {
        this._cubeBoundaries.miny = vertex.y
      }

      if (this._cubeBoundaries.maxy === null || this._cubeBoundaries.maxy < vertex.y) {
        this._cubeBoundaries.maxy = vertex.y
      }

      if (this._cubeBoundaries.minz === null || this._cubeBoundaries.minz > vertex.z) {
        this._cubeBoundaries.minz = vertex.z
      }

      if (this._cubeBoundaries.maxz === null || this._cubeBoundaries.maxz < vertex.z) {
        this._cubeBoundaries.maxz = vertex.z
      }
    })
  }

  /**
   * Пересекает ли куб другой куб
   * @param cubeData {CubeData}
   * @return boolean
   */
  intersect(cubeData) {
    let intersect = false

    for (let i = 0; i < cubeData.vertices.length; i ++) {
      const vertex = cubeData.vertices[i]
      if (this.inBoundaries(vertex)) {
        intersect = true
        break
      }
    }
    return intersect
  }

  /**
   * Находится ли вершина внутри куба
   * @param vertex {Vector3}
   * @return {boolean}
   */
  inBoundaries(vertex) {
    return vertex.x <= this._cubeBoundaries.maxx && vertex.x >= this._cubeBoundaries.minx &&
      vertex.y <= this._cubeBoundaries.maxy && vertex.y >= this._cubeBoundaries.miny &&
      vertex.z <= this._cubeBoundaries.maxz && vertex.z >= this._cubeBoundaries.minz
  }

  /**
   * Создание меша
   * @param intersectedCubes {CubeData[]}
   */
  createMesh(intersectedCubes) {
    intersectedCubes.forEach(cubeItem => {
      const ignoreY = cubeItem.position.y === this.position.y
      const ignoreX = cubeItem.position.x === this.position.x
      const ignoreZ = cubeItem.position.z === this.position.z

      cubeItem.vertexEdges.forEach(vertexEdges => {
        if (this.inBoundaries(vertexEdges.pivot)) {
          for (let rayOrientation in vertexEdges.rays) {
            if (vertexEdges.rays[rayOrientation]) {

              this.faces.forEach(faceItem => {
                const ignore = ignoreY && ['py','ny'].indexOf(rayOrientation) >= 0
                  || ignoreX && ['px','nx'].indexOf(rayOrientation) >= 0
                  || ignoreZ && ['pz','nz'].indexOf(rayOrientation) >= 0

                if (faceItem.orientation === rayOrientation && !ignore) {
                  faceItem.applyVertexEdge(vertexEdges)
                }

              })
            }

          }
        }
      })
    })
  }

  /**
   *
   * @return {CubeFaceMesh[]}
   */
  getShapes() {
    const meshes = []
    this._faces.forEach(faceItem => {
      const faceMesh = faceItem.getMesh()
      meshes.push(faceMesh)
    })

    return meshes
  }

}

export default CubeData
