/* eslint-disable */
import {Color3, Mesh, MeshBuilder, Space, StandardMaterial, Texture, TransformNode, Vector3} from "@babylonjs/core";

class TourLocationTag {

  _data

  /**
   * @type {TourCube}
   * @private
   */
  _tourCube

  /**
   * @type {TourSceneController}
   * @private
   */
  _sceneController

  /**
   * @type {Mesh}
   * @private
   */
  _mesh

  /**
   * @type {Mesh}
   * @private
   */
  _imageMesh

  /**
   * @type {Mesh}
   * @private
   */
  _imageContainer

  /**
   *
   * @return {Scene}
   * @private
   */
  get _scene() {
    return this._sceneController.scene
  }

  /**
   * @return {UniversalCamera}
   */
  get camera() {
    return this._sceneController.cameraController.camera
  }

  /**
   * @type {Vector3}
   * @private
   */
  get _position() {
    return this._data.position
  }

  /**
   *
   * @return {TourOptions}
   * @private
   */
  get _tourOptions() {
    return this._sceneController.tourOptions
  }

  /**
   * @return {TourSettings}
   * @private
   */
  get _tourSettings() {
    return this._sceneController.settings
  }

  get data() {
    return this._data
  }

  constructor({data, sceneController}) {
    this._tourCube = sceneController.getCubeById(data.cubeId)
    this._data = data
    this._sceneController = sceneController

    this._createMesh()

    this._scene.onBeforeRenderObservable.add(() => {
      this._update()
    })
  }

  _createMesh() {
    this._mesh = new Mesh('location_tag', this._scene)

    const lineWidth = 0.01
    const lineHeight = Math.abs(this._tourSettings.cubeSize / 2 - Math.abs(this._position.y))
    const line = MeshBuilder.CreateCylinder('location_tag_line', {
      diameter: lineWidth,
      height: lineHeight,
      sideOrientation: Mesh.DOUBLESIDE
    }, this._scene)

    const lineMaterial = new StandardMaterial('location_tag_line_material', this._scene)
    lineMaterial.diffuseColor = Color3.FromHexString('#F5F5DC')
    lineMaterial.emissiveColor = Color3.FromHexString('#F5F5DC')
    line.material = lineMaterial

    line.renderingGroupId = 2

    const lineNode = new TransformNode('location_tag_node', this._scene)
    line.parent = lineNode
    lineNode.parent = this._mesh
    line.position.y = -lineHeight / 2
    // lineNode.position.y = -this._tourSettings.cubeSize / 2

    const plane = MeshBuilder.CreatePlane('location_tag_plane', {
      size: 0.5,
      sideOrientation: Mesh.BACKSIDE
    }, this._scene)

    plane.renderingGroupId = 3

    const material = new StandardMaterial(`location_tag_material`, this._scene)

    material.diffuseTexture = new Texture(`/pics/navigation/${this._tourOptions.modelName}/locations/tag_${this._data.locationName}.png`, this._scene, true)
    material.diffuseTexture.hasAlpha = true
    material.diffuseTexture.uScale = -1
    material.diffuseTexture.vScale = 1
    material.emissiveColor = Color3.White()

    plane.material = material
    plane.parent = this._mesh

    this._mesh.position = this._tourCube.position.add(this._position)
    this._imageMesh = plane
  }

  _update() {
    if (this._data.containsIds.indexOf(this._sceneController.activeCube.id) >= 0) {
      if (this._mesh.scaling.x === 0) this._mesh.scaling = Vector3.One()
      this._imageMesh.lookAt(this.camera.position.clone(), null, null, null, Space.WORLD)
    } else {
      if (this._mesh.scaling.x > 0) this._mesh.scaling = Vector3.Zero()
    }
  }

  intersectRay(ray) {
    let intersect = false
    const pickInfoList = this._scene.multiPickWithRay(ray)
    pickInfoList.forEach(pickInfo => {
      if (pickInfo.pickedMesh === this._imageMesh) {
        intersect = true
      }
    })

    return intersect
  }
}

export default TourLocationTag
