
import { defineComponent, onMounted, ref, toRefs, PropType, watchEffect } from 'vue'
import SocketClient, {
  SocketClientCommands,
  SocketClientEvents,
  SocketEvent
} from '@/components/WebRtcRoom/SocketClient.class'

export const iceConfiguration = {
  iceServers: [
    // {
    //   urls: 'stun:stun1.l.google.com:19302'
    // },
    // {
    //   urls: 'stun:stun3.l.google.com:19302'
    // },
    // {
    //   urls: 'stun:stun4.l.google.com:19302'
    // },
    {
      urls: 'turn:77.246.159.219:3478',
      username: 'test',
      credential: 'wow'
    }
  ]
}

const offerSdpConstraints = {
  'mandatory': {
    'OfferToReceiveAudio': true,
    'OfferToReceiveVideo': true,
  },
  'optional': [],
};

export default defineComponent({
  name: 'PeerVideo',
  props: {
    socket: {
      type: Object as PropType<SocketClient>,
      required: true
    },
    offerData: {
      type: Object as PropType<{ clientId: number, offer?: string, answer?: string }>,
      required: false
    },
    localStream: {
      type: Object as PropType<MediaStream>,
      required: false,
    },
    local: {
      type: Boolean,
      default : false
    },
    requestCall: {
      type: Boolean,
      default: false,
    }
  },
  setup (props, { emit }) {
    const video = ref<HTMLVideoElement | null>(null)
    const refsProps = toRefs(props)
    const { local: isLocalVideo, requestCall } = refsProps

    const offerData = refsProps.offerData
    const socket = refsProps.socket.value as SocketClient
    const localStream = refsProps.localStream.value as MediaStream
    const callRequested = ref(false)

    let rtcPeerConnection : RTCPeerConnection | null = null
    let mediaStream = ref<MediaStream | null>(null)
    let remoteOfferSetted = false

    onMounted(async ()  => {
      video.value!.muted = true
      // if (offerData?.value?.offer || offerData?.value?.answer) {
      //   createRTCPeerConnection()
      // }
      // socket.addEventListener(SocketClientEvents.Answer, onSocketSdpAnswer)
      if (!isLocalVideo.value) socket.addEventListener(SocketClientEvents.IceCandidate, onSocketIceCandidate)

      if (isLocalVideo.value) {
        requestLocalStream()
      }

      if (requestCall.value === true) {
        createRTCPeerConnection().then(() => {
          call()
        })
      }

    })

    watchEffect(() => {
      if (offerData?.value?.answer || offerData?.value?.offer) {
        createRTCPeerConnection().then(() => {
          if (offerData?.value) setRemoteSdp(offerData?.value)
        })
      }

      console.log(requestCall.value)
      if (requestCall.value === true) {
        createRTCPeerConnection().then(() => {
          call()
        })
      }
    })

    const onEnableVideoClick = () : void => {
      requestLocalStream()
    }

    const setRemoteSdp = async ({ answer, offer } : { answer?: string, offer?: string }) => {
      if (!remoteOfferSetted && rtcPeerConnection) {
        remoteOfferSetted = true

        console.log('set remote sdp', {
          sdp: answer || offer,
          type: offer ? 'offer' : 'answer'
        })

        await rtcPeerConnection.setRemoteDescription({
          sdp: answer || offer,
          type: offer ? 'offer' : 'answer'
        })

        console.log('offer', !!offer, rtcPeerConnection)

        if (offer) {
          const answerSdp = await rtcPeerConnection.createAnswer()
          await rtcPeerConnection.setLocalDescription(answerSdp)
          socket.sendCommand(SocketClientCommands.AcceptCall, {
             offer: answerSdp.sdp
          })

          if (localStream) {
            console.log(localStream)
            localStream.getTracks().forEach((track) => {
              rtcPeerConnection?.addTrack(track)
            })
          }
        }
      }
    }

    const onSocketIceCandidate = (e: Event) : void => {
      if (rtcPeerConnection) {
        const data = (e as SocketEvent).data as { ice: any, from: { id : number }}
        if (refsProps.offerData.value && refsProps.offerData.value.clientId === data.from.id && data.ice !== null) {
          // console.log(data);
          rtcPeerConnection.addIceCandidate({
            'sdpMLineIndex': data.ice['sdpMLineIndex'],
            'sdpMid': data.ice['sdpMid'],
            'candidate': data.ice['candidate'],
          });
        }
      }
    }

    // const onSocketSdpAnswer = (e: Event) : void => {
    //   if (rtcPeerConnection) {
    //     const data = (e as SocketEvent).data as { offer: string, from: { id : number }}
    //
    //     if (offerData?.value?.clientId === data.from.id) {
    //       console.log('SETTED', data)
    //
    //       rtcPeerConnection.setRemoteDescription({
    //         sdp: data.offer,
    //         type: 'answer'
    //       }).catch((e) => {
    //         console.log('error on remode sdp', e.stack)
    //       })
    //     }
    //   }
    // }

    const onPeerConnectionIceCandidate = (e: RTCPeerConnectionIceEvent) => {
      socket.sendCommand(SocketClientCommands.Ice, {
        ice: e.candidate
      })
    }

    const onPeerConnectionTrack = (e: RTCTrackEvent) : void => {
      if (video.value) {
        // if (!mediaStream.value) mediaStream.value = new MediaStream()
        // mediaStream.value.addTrack(e.track)
        // video.value.srcObject = mediaStream.value
        // video.value.muted = false
        if (e.streams.length > 0) {
          video.value.srcObject = e.streams[0]
          video.value.muted = false
        }
        console.log('ontrack', e)
      }

      if (mediaStream.value) mediaStream.value.addTrack(e.track)
    }

    const createRTCPeerConnection = async (stream?: MediaStream) : Promise<RTCPeerConnection | null> => {
      if (!rtcPeerConnection && !isLocalVideo.value) {
        rtcPeerConnection = new RTCPeerConnection(iceConfiguration)
        rtcPeerConnection.addEventListener('track', onPeerConnectionTrack)

        if (localStream) {
          localStream.getTracks().forEach((track) => {
            rtcPeerConnection?.addTrack(track)
          })
        }

        rtcPeerConnection.addEventListener('icecandidate', onPeerConnectionIceCandidate)
        rtcPeerConnection.addEventListener('connectionstatechange', (e) => {
          console.log('STATE', rtcPeerConnection?.connectionState)
        })

        return rtcPeerConnection
      }

      return null
    }

    const call = async () => {
      if (rtcPeerConnection && !callRequested.value) {
        callRequested.value = true

        const rtcDescription = await rtcPeerConnection.createOffer()
        await rtcPeerConnection.setLocalDescription(rtcDescription)

        // console.log(rtcDescription);
        socket.sendCommand(SocketClientCommands.Call, {
          offer: rtcDescription.sdp
        })
      }
    }

    const requestLocalStream = async () : Promise<void> => {
      const videoEl = video.value!
      mediaStream.value = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      })

      emit('local-media-stream', mediaStream.value)
      videoEl.srcObject = mediaStream.value
    }

    return {
      video,
      mediaStream,
      onEnableVideoClick
    }
  }
})
