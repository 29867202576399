import SceneController from "@/classes/SceneController.class";
import {Camera, Mesh} from "@babylonjs/core";
import BlobedSphereModel from "@/components/BlobedSphere/classes/sceneItems/BlobedSphereModel.class";

export default class VrSceneController extends SceneController {
  private _blobedSphereModel!: BlobedSphereModel

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()
    this._createWebXRSession()

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () => {
    this._blobedSphereModel.mesh.rotation.x += 0.01
    this._blobedSphereModel.mesh.rotation.y += 0.01
  }

  private _createWebXRSession() : void {
    const env = this.scene.createDefaultEnvironment()

    this._blobedSphereModel = new BlobedSphereModel({
      sceneController: this
    })

    this._blobedSphereModel.setSpeed(0.3)
    this._blobedSphereModel.setRadius(0.5)

    this.scene.createDefaultXRExperienceAsync({
      floorMeshes: [env?.ground as Mesh]
    })
  }
}
