import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {
  BlurPostProcess,
  DefaultRenderingPipeline,
  FxaaPostProcess,
  PostProcess,
  Texture,
  Vector2, Vector3
} from "@babylonjs/core";
import MusicPlayer from "@/components/SdfTexturedCube/classes/MusicPlayer.class";

export default class TexturedCubeSceneItem extends SceneItem {
  private _time = 0
  private _postProcess!: PostProcess
  private _fxaaPostProcess!: FxaaPostProcess
  private _mouseVector: Vector2 = Vector2.Zero()
  private _logoSampler: Texture

  private _postProcesses: PostProcess[] = []

  private _musicPlayer!: MusicPlayer
  private _backgroundColor: Vector3 = new Vector3(0.05, 0.05, 0.50)

  private _defaultPipeline:DefaultRenderingPipeline

  constructor(props: SceneItemArgs) {
    super(props)
    this._logoSampler = new Texture('/projects/sdf-cube/logo-black-bg.jpg', this.scene, false, true, Texture.NEAREST_SAMPLINGMODE)
    this._createEffect()

    this._defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, [this.camera])
    this._defaultPipeline.bloomEnabled = true
    // defaultPipeline.fxaaEnabled = true
    this._defaultPipeline.bloomWeight = 1
    this._defaultPipeline.bloomScale = 1
    this._defaultPipeline.bloomKernel = 64
    this._defaultPipeline.bloomThreshold = 1

    this.scene.onBeforeRenderObservable.add(this._update)
    window.addEventListener('mousemove', this._onMouseMove)
  }

  private _onMouseMove = (e : MouseEvent) => {
    this._mouseVector.x = e.clientX / window.innerWidth
    this._mouseVector.y = e.clientY / window.innerHeight
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
  }

  private _createEffect() {
    const cubePostProcess = new PostProcess(
      'occlusion',
      '/projects/sdf-cube/fx/cube',
      ['time', 'mouse', 'screenSize', 'bgColor'],
      ['logoSampler'],
      1.,
      this.camera
    )

    cubePostProcess.onApply = (effect) => {

      const fftData : Uint8Array | null =  this._musicPlayer.getFft()

      if (fftData) {

        let x = 0
        let y = 0
        let z = 0

        for (let i = 0; i < 10; i ++) {
          x += Math.abs(128 - fftData[i])
        }
        for (let i = 42; i < 84; i ++) {
          y += Math.abs(128 - fftData[i])
        }
        for (let i = 90; i < 126; i ++) {
          z += Math.abs(128 - fftData[i])
        }

        this._backgroundColor.x += ((x / 10) / 128 - this._backgroundColor.x) / 3
        this._backgroundColor.y += ((y / 42) / 128 - this._backgroundColor.y) / 3
        this._backgroundColor.z += ((z / 36) / 128 - this._backgroundColor.z) / 3
      }

      this._defaultPipeline.bloomWeight = this._backgroundColor.z * 8
      this._defaultPipeline.bloomThreshold = this._backgroundColor.z * 2

      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', new Vector2(cubePostProcess.width, cubePostProcess.height))
      effect.setVector2('mouse', this._mouseVector)
      effect.setTexture('logoSampler', this._logoSampler)

      effect.setVector3('bgColor', this._backgroundColor)
    }

    this._postProcess = cubePostProcess

    this._postProcesses.push(cubePostProcess)
  }

  public setMusicPlayer(mp: MusicPlayer) : void {
    this._musicPlayer = mp
  }

  public dispose() : void {
    //this._postProcess.dispose(this.camera)
    this._postProcesses.forEach(item => {
      item.dispose(this.camera)
    })
    if (this._fxaaPostProcess) this._fxaaPostProcess.dispose(this.camera)
    window.removeEventListener('mousemove', this._onMouseMove)
  }
}
