import SceneController from "./../../SceneController.class";
import {Camera, Scene} from "@babylonjs/core";

interface SceneItemArgs {
  sceneController: SceneController
}

class SceneItem extends EventTarget {
  protected _sceneController: SceneController

  get sceneController() : SceneController {
    return this._sceneController
  }

  get camera() : Camera {
    return this.sceneController.cameraController.camera
  }

  get scene(): Scene {
    return this._sceneController.scene
  }

  get canvas(): HTMLCanvasElement {
    return this._sceneController.canvas
  }

  constructor(props: SceneItemArgs) {
    super()
    this._sceneController = props.sceneController
  }

}

export {SceneItem, SceneItemArgs}
