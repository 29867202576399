
import {Vue} from "vue-class-component";
import {reactive} from "vue";

interface RequestAccelerometerState {
  visible: boolean
}

export default class RequestAccelerometer extends Vue {
  public state : RequestAccelerometerState = reactive({
    visible: false
  })

  get needPermission() : boolean {
    return DeviceOrientationEvent.requestPermission !== undefined
  }

  mounted() {
    this.state.visible = this.needPermission

    if (!this.needPermission) {
      this.$emit('granted', true)
    }
  }

  public onAllowClick() : void {
    if (DeviceOrientationEvent) {
      DeviceOrientationEvent.requestPermission().then((result) => {
        if (result === 'granted') {
          this.$emit('granted', true)
        }

        this.state.visible = false
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  public onCancelClick() : void {
    this.$emit('granted', false)
    this.state.visible = false
  }
}
