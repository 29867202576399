import SceneController from '@/classes/SceneController.class'
import {
  ArcRotateCamera,
  Color3,
  Color4,
  HemisphericLight,
  Mesh,
  MeshBuilder, Ray, RayHelper,
  Vector3
} from '@babylonjs/core'
import AutoGridItem, {AutoGridItemEvents} from '@/components/DepthMap/classes/AutoGridItem.class'

export default class DepthMapSceneController extends SceneController {
  private _pointMeshesList: Mesh[] = []
  private _cameraMesh: Mesh

  private _points: Vector3[] = []

  private _rayHelper!: RayHelper

  private _tempMesh!: Mesh

  constructor({ canvas } : { canvas : HTMLCanvasElement }) {
    super({ canvas })

    this.scene.clearColor = new Color4(0, 0, 0, 1)
    this.createCameraController()

    const camera = (this.cameraController.camera as ArcRotateCamera)
    camera.setTarget(Vector3.Zero())

    this._cameraMesh = MeshBuilder.CreateBox('center', {
      size: 0.1
    }, this.scene)

    this._tempMesh = MeshBuilder.CreateBox('intersect', {
      size: 0.01
    }, this.scene)

    this._cameraMesh.showBoundingBox = true
    this._cameraMesh.isPickable = false

    const light = new HemisphericLight('hemi', new Vector3(1,-1,0), this.scene)
    light.diffuse = Color3.Yellow()
    light.intensity = 0.5

    // const ray = new Ray(this._cameraMesh.position, new Vector3(0,0,1))
    // this._rayHelper = RayHelper.CreateAndShow(ray, this.scene, Color3.Yellow())
    //
    // console.log('MESH', this.scene.pickWithRay(ray, (mesh) => true)?.pickedMesh?.name)

    // let index = 0
    // this.scene.onBeforeRenderObservable.add(() => {
    //   this._pointMeshesList.forEach((item) => item.update())
    //   // if (this._pointMeshesList.length > 0) {
    //   //   //const index = Math.round(Math.random() * (this._pointMeshesList.length - 1))
    //   //   const cube = this._pointMeshesList[index]
    //   //   const direction = cube.position.subtract(this._cameraMesh.position)
    //   //   ray.direction = direction
    //   //
    //   //   index++
    //   //   if (index >= this._pointMeshesList.length) index = 0
    //   //   // console.log('MESH', this.scene.pickWithRay(ray, (mesh) => mesh.isPickable)?.pickedMesh?.name)
    //   //   //this._rayHelper.ray = ray
    //   // }
    // })
  }

  public setDepthPoints(points: Vector3[], cameraXRot : number, cameraYRot : number) : void {
    console.log('set points')
    // this._pointMeshesList.forEach((mesh) => mesh.dispose())
    this._cameraMesh.rotation.x = cameraXRot
    this._cameraMesh.rotation.y = cameraYRot

    setTimeout(() => {
      points.forEach((depthPoint) => {
        const position = this._positionFromDepthPoint(depthPoint)
        const globalPosition = this._localToGlobal(position)

        this._addPoint(globalPosition)

        // const gridSize = 30
        // const x = Math.round((globalPosition.x * 100 / gridSize) * gridSize) / 100
        // const y = Math.round((globalPosition.y * 100 / gridSize) * gridSize) / 100
        // const z = Math.round((globalPosition.z * 100 / gridSize) * gridSize) / 100
        //
        // this._addPoint(new Vector3(x * 1, y * 1, z * 1))
      })

      // setTimeout(() => {
      //   this._pointMeshesList.forEach((item) => item.reset())
      // }, 0)

    }, 0)
  }

  private _addPoint(vector: Vector3) : void {
    // const cube = new AutoGridItem({
    //   sceneController: this
    // })
    // cube.init(this._cameraMesh.position, vector)
    //
    // this._pointMeshesList.push(cube)

    // cube.addEventListener(AutoGridItemEvents.Destroy, () => {
    //   this._pointMeshesList = this._pointMeshesList.filter((item) => item !== cube)
    // })

    // const deleteList : Vector3[] = []
    // const cubeName = vector.toString()
    //
    // const foundedCubes = []
    //
    // const direction = vector.clone().subtract(this._cameraMesh.position).normalize()
    // let vectorDistance = Vector3.Distance(this._cameraMesh.position, vector)
    // const step = 0.05
    //
    const cube = MeshBuilder.CreateBox('cloudpoint', {
      size: 0.05
    }, this.scene)
    cube.position = vector.clone()

    this._pointMeshesList.push(cube)
  }

  private _localToGlobal(position: Vector3) : Vector3 {
    const globalMatrix = this._cameraMesh.getWorldMatrix()
    return Vector3.TransformCoordinates(position, globalMatrix)
  }

  private _positionFromDepthPoint (depthPoint: Vector3) : Vector3 {
    // const position = Vector3.Zero()
    // const scale = 2
    // const focalLength = 1
    // const scaleFactor = 1
    //
    // position.z = depthPoint.z * scale
    // position.x = (depthPoint.x - 0.5) * scale
    // position.y = (depthPoint.y - 0.5) * scale

    // console.log(position.z)

    depthPoint.x = (depthPoint.x - 0.5) * 20
    depthPoint.y = (depthPoint.y - 0.5) * 15

    const focalx = 20
    const focaly = 7
    const d = depthPoint.z * 10

    const position = Vector3.Zero()

    const xOverZ = (depthPoint.x) / focalx
    const yOverZ = (depthPoint.y) / focaly

    //const z = d / Math.sqrt(1. + xOverZ*xOverZ + yOverZ*yOverZ)
    const z = d / Math.sqrt(1. + xOverZ*xOverZ + yOverZ*yOverZ)
    const x = xOverZ * z
    const y = yOverZ * z

    position.x = x //depthPoint.x
    position.y = y //depthPoint.y
    position.z = d

    // console.log(depthPoint, position)
    // console.log(depthPoint)

    return position
  }
}
