import CubeVertexRay from "./CubeVertexRay.class";

/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeVertexEdges {
  /**
   * @type {Vector3}
   */
  pivot

  /**
   * @type {Vector3[]}
   */
  vertices

  /**
   *
   * @type {{px: CubeVertexRay, py: CubeVertexRay, pz: CubeVertexRay, nx: CubeVertexRay, ny: CubeVertexRay, nz: CubeVertexRay}}
   */
  rays = {
    pz: null,
    nz: null,
    px: null,
    nx: null,
    py: null,
    ny: null
  }

  constructor({pivot, vertices}) {
    this.pivot = pivot
    this.vertices = vertices

    this._createRays()
  }

  _createRays() {
    this.vertices.forEach(vertex => {
      if (vertex.z - this.pivot.z > 0) this.rays.pz = new CubeVertexRay({pivot: this.pivot, vertex})
      if (vertex.z - this.pivot.z < 0) this.rays.nz = new CubeVertexRay({pivot: this.pivot, vertex})

      if (vertex.x - this.pivot.x > 0) this.rays.px = new CubeVertexRay({pivot: this.pivot, vertex})
      if (vertex.x - this.pivot.x < 0) this.rays.nx = new CubeVertexRay({pivot: this.pivot, vertex})

      if (vertex.y - this.pivot.y > 0) this.rays.py = new CubeVertexRay({pivot: this.pivot, vertex})
      if (vertex.y - this.pivot.y < 0) this.rays.ny = new CubeVertexRay({pivot: this.pivot, vertex})
    })
  }
}

export default CubeVertexEdges
