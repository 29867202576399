import SceneController from "@/classes/SceneController.class";
import {Color3, Color4} from "@babylonjs/core";
import LavaWorldSceneItem from "@/components/Index/classes/LavaWorldSceneItem.class";

export default class IndexSceneController extends SceneController {
  private _lavaWorld!: LavaWorldSceneItem | null

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()
    this.scene.clearColor = new Color4(0, 0, 0, 1);

    this.enableEffect()
  }

  public setOptions({color}: {color: Color3}): void {
    if (this._lavaWorld) {
      this._lavaWorld.setOptions({color})
    }
  }

  private _createLavaWorld() : void {
    this.engine.setHardwareScalingLevel(1);
    this._lavaWorld = new LavaWorldSceneItem({
      sceneController: this
    })
  }

  public enableEffect() : void {

    this.scene.clearColor = new Color4(0, 0, 0, 1);

    if (this._lavaWorld) {
      this._lavaWorld.dispose()
      this._lavaWorld = null
    }

    this._createLavaWorld()
  }
}
