import SceneController from "@/classes/SceneController.class";
import PenguinModel from "@/components/WaterEffect/classes/sceneItems/PenguinModel.class";

export default class WaterEffectSceneController extends SceneController {
  private _penguinModel: PenguinModel

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})

    this.createCameraController()
    this._penguinModel = new PenguinModel({
      sceneController: this
    })
  }
}
