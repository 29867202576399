import SceneController from "@/classes/SceneController.class";
import * as poseDetection from '@tensorflow-models/pose-detection';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-webgpu';
import * as mpPose from '@mediapipe/pose';

import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import {Color3, Color4, Mesh, MeshBuilder, StandardMaterial} from "@babylonjs/core";

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
    tfjsWasm.version_wasm}/dist/`);
// import '@mediapipe/pose'

interface DetectedPoint {
  name: string,
  model: Mesh
}

export default class PoseDetectionSceneController extends SceneController {
  private _videoElement: HTMLVideoElement
  private _videoCanvas!: HTMLCanvasElement
  private _videoCanvasContext!: CanvasRenderingContext2D
  // private _poseDetector!: posenet.PoseNet
  private _blazePoseDetector!: poseDetection.PoseDetector

  private _animationRequested = false

  private _pointsMap: Map<string, Mesh> = new Map<string, Mesh>()

  private _mat: StandardMaterial

  constructor({canvas, video} : {canvas: HTMLCanvasElement, video: HTMLVideoElement}) {
    super({canvas})
    this.createCameraController()

    this.cameraController.camera.radius = 3

    this.scene.clearColor = new Color4(0,0,0, 0.1)
    this.engine.setSize(640, 480)

    this._mat = new StandardMaterial('test', this.scene)
    this._mat.diffuseColor = Color3.White()
    this._mat.emissiveColor = Color3.White()
    this._mat.disableLighting = true

    this._videoElement = video
    this._videoElement.addEventListener('loadedmetadata', this._onMetaData)
    this._requestVideo()
  }

  private _onMetaData = () : void => {
    this._createVideoCanvas()
    this._createDetector()
  }

  private _createVideoCanvas() : void {
    this._videoCanvas = document.createElement('canvas')
    this._videoCanvas.width = this._videoElement.videoWidth
    this._videoCanvas.height = this._videoElement.videoHeight

    this._videoCanvasContext = this._videoCanvas.getContext('2d')!
  }

  private async _requestVideo() : Promise<void> {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: 640,
        height: 480
      }
    })

    this._videoElement.srcObject = localStream
  }

  private async _createDetector() : Promise<void> {
    // this._poseDetector = await posenet.load({
    //   architecture: 'MobileNetV1',
    //   outputStride: 16,
    //   inputResolution: { width: 640, height: 480 },
    //   multiplier: 0.75
    // })

    this._blazePoseDetector = await poseDetection.createDetector(poseDetection.SupportedModels.BlazePose, {
      runtime: 'mediapipe',
      enableSmoothing: true,
      solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
      modelType: 'full',
    })

    this._animationRequested = true
    this._requestAnimationFrame()
  }

  private _drawImage() : void {
    this._videoCanvasContext.drawImage(this._videoElement, 0, 0, this._videoElement.videoWidth, this._videoElement.videoHeight)
  }

  private _requestAnimationFrame() : void {
    if (this._animationRequested) {
      window.requestAnimationFrame(() => {
        // this._drawImage()

        // console.log(this._videoElement.videoWidth)
        // this._poseDetector.estimateSinglePose(this._videoElement).then((poses) => {
        //   console.log(poses)
        //   this._requestAnimationFrame()
        // })
        this._blazePoseDetector.estimatePoses(this._videoElement, {maxPoses: 1, flipHorizontal: false}).then((poses) => {
          if (poses.length > 0) {
            this._applyPose(poses[0])
          }
          this._requestAnimationFrame()
        })
      })
    }
  }

  private _applyPose(pose: poseDetection.Pose) : void {
    // console.log(pose)
    const points = pose.keypoints3D!

    points.forEach((point) => {
      const {name, x, y, z, score} = point
      let model: Mesh | undefined = this._pointsMap.get(name!)

      if (!model) {
        model = MeshBuilder.CreateSphere(name!, {
          diameter: 0.03
        }, this.scene)

        model.material = this._mat
        this._pointsMap.set(name!, model)
      }

      model.position.x = (x || 0) * 1.2
      model.position.y = (-y || 0) * 1.2
      model.position.z = (z || 0) * 1.2

      if (score! * 1 > 0.9) {
        model.scaling.x = model.scaling.y = model.scaling.z = 1
      } else {
        model.scaling.x = model.scaling.y = model.scaling.z = 0
      }
    })
  }

  public dispose() : void {
    if (this._videoCanvas) this._videoCanvas.remove()
    super.dispose();
  }
}
