import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {PBRCustomMaterial} from "@babylonjs/materials";
import {PBRMaterial, ShaderMaterial} from "@babylonjs/core";

export default class PenguinModel extends ModelController {
  private _time = 0
  private _material!: ShaderMaterial
  private _pbrCustomMaterial!: PBRCustomMaterial

  private _usePbrCustom = true

  constructor(args: SceneItemArgs) {
    super(args)
    this.loadModelFromUrl('/projects/water-effect/models/penguin.glb').then((assetContainer) => {
      assetContainer.animationGroups.forEach((animationGroup) => {
        animationGroup.play(true)
      })
      if (this._usePbrCustom) {
        this._updateModelPbr()
      } else {
        this._updateModel()
      }
    })

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    this._time += this.sceneController.engine.getDeltaTime()
    if (this.mesh && this._material) {
      if (!this._usePbrCustom) {
        this._material.setFloat('time', this._time)
      }
    }
  }

  private _updateModelPbr() : void {
    this.mesh!.getChildMeshes().forEach((mesh) => {
      if (mesh.name === 'P_Platforma') {
        const oldMat: PBRMaterial = mesh.material as PBRMaterial
        const pbrMat: PBRCustomMaterial = new PBRCustomMaterial("pbr-water-material", this.scene)
        pbrMat.albedoColor = oldMat.albedoColor
        pbrMat.albedoTexture = oldMat.albedoTexture
        pbrMat.alpha = oldMat.alpha
        pbrMat.ambientColor = oldMat.ambientColor
        pbrMat.ambientTexture = oldMat.ambientTexture
        pbrMat.backFaceCulling = oldMat.backFaceCulling
        pbrMat.bumpTexture = oldMat.bumpTexture
        pbrMat.emissiveColor = oldMat.emissiveColor
        pbrMat.emissiveTexture = oldMat.emissiveTexture
        pbrMat.emissiveIntensity = oldMat.emissiveIntensity
        pbrMat.environmentBRDFTexture = oldMat.environmentBRDFTexture
        pbrMat.fogEnabled = oldMat.fogEnabled
        pbrMat.lightmapTexture = oldMat.lightmapTexture
        pbrMat.metallic = oldMat.metallic
        pbrMat.metallicTexture = oldMat.metallicTexture
        pbrMat.reflectionColor = oldMat.reflectionColor
        pbrMat.reflectionTexture = oldMat.reflectionTexture
        pbrMat.reflectivityColor = oldMat.reflectivityColor
        pbrMat.reflectivityTexture = oldMat.reflectivityTexture
        pbrMat.specularIntensity = oldMat.specularIntensity
        pbrMat.transparencyMode = oldMat.transparencyMode
        pbrMat.twoSidedLighting = oldMat.twoSidedLighting
        pbrMat.twoSidedLighting = oldMat.twoSidedLighting


        pbrMat.AddUniform('time', 'float', null)
        pbrMat.Vertex_Definitions('float random (in vec2 st) {\n' +
          '    return fract(sin(dot(st.xy, vec2(12.9898,78.233)))*43758.5453123);\n' +
          '}\n' +
          '\n' +
          '// Based on Morgan McGuire @morgan3d\n' +
          '// https://www.shadertoy.com/view/4dS3Wd\n' +
          'float noise (in vec2 st) {\n' +
          '    vec2 i = floor(st);\n' +
          '    vec2 f = fract(st);\n' +
          '\n' +
          '    // Four corners in 2D of a tile\n' +
          '    float a = random(i);\n' +
          '    float b = random(i + vec2(1.0, 0.0));\n' +
          '    float c = random(i + vec2(0.0, 1.0));\n' +
          '    float d = random(i + vec2(1.0, 1.0));\n' +
          '\n' +
          '    vec2 u = f * f * (3.0 - 2.0 * f);\n' +
          '\n' +
          '    return mix(a, b, u.x) +\n' +
          '            (c - a)* u.y * (1.0 - u.x) +\n' +
          '            (d - b) * u.x * u.y;\n' +
          '}\n' +
          '\n' +
          '#define OCTAVES 2\n' +
          'float fbm (in vec2 st) {\n' +
          '    // Initial values\n' +
          '    float value = 0.0;\n' +
          '    float amplitude = .5;\n' +
          '    float frequency = 0.;\n' +
          '    //\n' +
          '    // Loop of octaves\n' +
          '    for (int i = 0; i < OCTAVES; i++) {\n' +
          '        value += amplitude * noise(st);\n' +
          '        st *= 2.;\n' +
          '        amplitude *= .5;\n' +
          '    }\n' +
          '    return value;\n' +
          '}')
        pbrMat.Vertex_Before_PositionUpdated('' +
          'float t = time * 0.001;' +
          'float noise = fbm(vec2(position.x + t, position.y + position.z));\n' +
          'float distortion = noise * .5;' +
          'positionUpdated = position + normal * distortion;'
        )

        pbrMat.onBindObservable.add(() => {
          pbrMat.getEffect().setFloat('time', this._time)
        })

        //console.log(pbrMat.VertexShader)

        mesh.material = pbrMat

        this._pbrCustomMaterial = pbrMat
      }
    })
  }

  private _updateModel() : void {
    this.mesh!.getChildMeshes().forEach((mesh) => {
      if (mesh.name === 'P_Platforma') {
        const oldMat: PBRMaterial = mesh.material as PBRMaterial
        const pbrMat: ShaderMaterial = new ShaderMaterial("water-material", this.scene, "/projects/water-effect/fx/water", {
          attributes: ["position", "normal", "uv"],
          uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
          needAlphaBlending: true,
        })

        // pbrMat.setTexture('textureSampler', oldMat.albedoTexture)

        mesh.material = pbrMat

        this._material = pbrMat
      }
    })
  }
}
