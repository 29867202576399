import {Color3, Material, Mesh, SceneLoader, Vector3} from "@babylonjs/core";
import * as BABYLON from "@babylonjs/core";

export default class TourRoomModel {
  /**
   *
   * @private {TourCube}
   */
  _tourCube

  /**
   * @private {string}
   */
  _modelUrl

  /**
   * @private {Vector3}
   */
  _position

  /**
   * @private {Mesh}
   */
  _mesh

  /**
   * @type {TourSceneController}
   * @private
   */
  _sceneController

  /**
   *
   * @return {Scene}
   * @private
   */
  get _scene() {
    return this._sceneController.scene
  }

  constructor({cube, modelUrl, position, sceneController}) {
    this._tourCube = cube
    this._modelUrl = modelUrl
    this._position = position
    this._sceneController = sceneController

    this._scene.onBeforeRenderObservable.add(this._update)

    this._loadModel()
  }

  _update = () => {
    if (this._mesh) {
      this._mesh.getChildMeshes().filter((mesh) => mesh.material).forEach(mesh => {
        if (this._tourCube === this._sceneController.activeCube) {
          mesh.material.alpha += 0.1
        } else {
          mesh.material.alpha -= 0.1
        }

        if (mesh.material.alpha < 0) mesh.material.alpha = 0
        if (mesh.material.alpha > 1) mesh.material.alpha = 1
      })
    }
  }

  _loadModel() {
    this._mesh = new Mesh('room_model_root', this._scene)
    const splittedUrl = this._modelUrl.split('/')
    const fileName = splittedUrl[splittedUrl.length - 1]
    splittedUrl.pop()
    const path = splittedUrl.join('/') + '/'

    SceneLoader.LoadAssetContainer(path, fileName, this._scene, (container) => {
      container.addAllToScene()

      container.meshes.forEach(meshItem => {
        meshItem.isPickable = false
        if (meshItem.material) {
          meshItem.material.unlit = true
          // meshItem.material.baseTexture.hasAlpha = true
          // meshItem.material.albedoTexture.hasAlpha = true
          // meshItem.material.useAlphaFromAlbedoTexture = true
          meshItem.material.transparencyMode = Material.MATERIAL_ALPHABLEND
        }
        if (!meshItem.parent) meshItem.parent = this._mesh
      })

      /**
       * @todo Убрать скейлинг
       */
      this._mesh.scaling = new Vector3(0.2, 0.2, 0.2)
      this._mesh.position = this._tourCube.position.add(this._position)
    })
  }
}
