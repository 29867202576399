
import {Vue} from "vue-class-component";
import IndexSceneController from "@/components/Index/classes/IndexSceneController.class";

export default class Index extends Vue {
  private _sceneController!: IndexSceneController

  public mounted() : void {
    this._sceneController = new IndexSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }

  public unmounted() : void {
    this._sceneController.dispose()
  }
}
