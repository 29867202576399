/**
 * @author Aleksand Simonov aka fatalius,
 */
class CubeVertexRay {
  /**
   * @private {Vector3}
   */
  _pivot

  /**
   * @private {Vector3}
   */
  _vertex

  get vertex () {
    return this._vertex
  }

  get pivot () {
    return this._pivot
  }

  constructor({pivot, vertex}) {
    this._pivot = pivot
    this._vertex = vertex
  }
}

export default CubeVertexRay
