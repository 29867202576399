import {Camera, Matrix, Quaternion, Vector3} from '@babylonjs/core'
import ArMarkerSceneController from '@/components/MarkerLogo/classes/ArMarkerSceneController.class'
import isMobile from 'is-mobile'

export enum MarkerVideoEvents {
  MarkerFounded = 'MarkerFounded',
  MarkerLost = 'MarkerLost',
  MarkerLoaded = 'MarkerLoaded'
}

export default class MarkerVideo extends EventTarget {
  private _videoElement: HTMLVideoElement
  private _controller: ArMarkerSceneController
  private _animationFrameEnabled = true

  private _arkitWorker!: Worker
  private _canvas: HTMLCanvasElement
  private _context: CanvasRenderingContext2D

  private _videoSize: {width: number, height: number} = {width: 640, height: 480}
  private _viewSize: {width: number, height: number} = {width: 640, height: 480}

  private _world: number[] | null = null
  private _interpolationFactorPosition = 10
  private _interpolationFactorRotation = 10
  private _markerDetected = false
  private _mobileDevice = false

  private _trackedMatrix = {
    delta: [
      0,0,0,0,
      0,0,0,0,
      0,0,0,0,
      0,0,0,0
    ],
    interpolated: [
      0,0,0,0,
      0,0,0,0,
      0,0,0,0,
      0,0,0,0
    ]
  }

  private get _camera(): Camera {
    return this._controller.camera
  }

  constructor({video, controller, positionFactor, rotationFactor } : {
    video: HTMLVideoElement,
    controller: ArMarkerSceneController,
    positionFactor?: number,
    rotationFactor?: number
  }) {
    super()
    this._controller = controller
    this._videoElement = video

    this._mobileDevice = isMobile({tablet: true})

    if (positionFactor !== undefined) {
      this._interpolationFactorPosition = positionFactor
    } else {
      this._interpolationFactorPosition = this._mobileDevice ? 3 : 5
    }

    if (rotationFactor !== undefined) {
      this._interpolationFactorRotation = rotationFactor
    } else {
      this._interpolationFactorRotation = this._mobileDevice ? 5 : 7
    }

    this._requestLocalStream()

    this._canvas = document.createElement('canvas')
    this._context = this._canvas.getContext('2d')!

    // document.body.appendChild(this._canvas)
    // this._canvas.style.position = 'absolute'
    // this._canvas.style.top = '0px'
    // this._canvas.style.left = '0px'

    this._requestAnimationFrame()
  }

  private _dataToArray(data: string) : number[] {
    const obj = JSON.parse(data)
    const arr: number[] = []
    for (const key in obj) {
      arr.push(obj[key])
    }
    return arr
  }

  private _createWorker(markerUrl: string) : void {
    this._arkitWorker = new Worker('/artoolkit/js/artoolkit.worker.js')
    this._arkitWorker.postMessage({
      type: 'load',
      pw: 640,
      ph: 480,
      camera_para: '/artoolkit/Data/camera_para-iPhone 5 rear 640x480 1.0m.dat',
      marker: markerUrl
    })

    this._arkitWorker.onmessage = (e) => {
      const message = e.data
      switch (message.type) {
        case 'loaded': {
          // const arr = this._dataToArray(message['proj'] as string)
          // const matrix = this._camera.getProjectionMatrix(true)

          // const m = matrix.m as Float32Array

          // m[5] = arr[5]
          // const newMatrix = Matrix.FromArray(m)
          // this._camera.freezeProjectionMatrix(newMatrix)
        }
          break

        case 'endLoading': {
          if (message.end) this.dispatchEvent(new Event(MarkerVideoEvents.MarkerLoaded))
        }
          break

        case 'found': {
          const arr = this._dataToArray(message['matrixGL_RH'] as string)
          this._found(arr)
          this.dispatchEvent(new Event(MarkerVideoEvents.MarkerFounded))
        }
          break

        case "not found": {
          this._world = null
          this._markerDetected = false
          this.dispatchEvent(new Event(MarkerVideoEvents.MarkerLost))
          break
        }
      }
      this._process()
    }
  }

  private _found(arr: number[]) : void {
    this._world = arr
  }

  async _requestLocalStream() : Promise<void> {
    const constrains = this._mobileDevice ? {
      audio: false,
      video: {
        width: this._videoSize.width,
        height: this._videoSize.height,
        frameRate: {
          min: 25,
          max: 60
        },
        facingMode: 'environment'
      }
    } : {
      audio: false,
      video: {
        width: this._videoSize.width,
        height: this._videoSize.height,
        frameRate: {
          min: 25,
          max: 60
        }
      }
    }

    let localStream : MediaStream | null = null

    try {
      localStream = await navigator.mediaDevices.getUserMedia(constrains)
    } catch (e) {
      if (this._mobileDevice) {
        let envCameraId = ''
        const devicesList = await navigator.mediaDevices.enumerateDevices()
        devicesList.forEach((deviceItem) => {
          if (deviceItem.kind === 'videoinput' && deviceItem.label.indexOf('back') >= 0) {
            envCameraId = deviceItem.deviceId
          }
        })

        const envCameraConstrains = {
          audio: false,
          video: {
            width: this._videoSize.width,
            height: this._videoSize.height,
            frameRate: {
              min: 25,
              max: 60
            },
            deviceId: envCameraId
          }
        }

        localStream = await navigator.mediaDevices.getUserMedia(envCameraConstrains)
      }
    }

    this._videoElement.srcObject = localStream
    this._videoElement.addEventListener('loadedmetadata', this._onVideoMetaData)
  }

  private _onVideoMetaData = () : void => {
    const {videoWidth, videoHeight} = this._videoElement

    this._videoElement.style.width = videoWidth + 'px'
    this._videoElement.style.height = videoHeight + 'px'

    if (this._mobileDevice) {
      this._canvas.height = 480
      this._canvas.width = 640

      this._canvas.style.height = 240 + 'px'
      this._canvas.style.width = 320 + 'px'
    } else {
      this._canvas.width = videoWidth
      this._canvas.height = videoHeight

      this._canvas.style.width = videoWidth + 'px'
      this._canvas.style.height = videoHeight + 'px'
    }

    this._videoSize.width = videoWidth
    this._videoSize.height = videoHeight

    this.setViewSize(this._viewSize)
  }

  private _requestAnimationFrame() : void {
    if (this._animationFrameEnabled) {
      window.requestAnimationFrame(() => {
        this._update()
        this._requestAnimationFrame()
      })
    }
  }

  private _process() : void {
    if (this._mobileDevice) {
      this._context.fillRect(0,0, 640, 480)

      if (this._viewSize.width > this._viewSize.height) {
        this._context.drawImage(this._videoElement, 0, 0, 640, 480, 0,0, 640, 480)
      } else {
        const ratio = 480 / 640
        const destWidth = 480 * ratio
        this._context.drawImage(this._videoElement, 0, 0, 480, 640, 640 / 2 - destWidth / 2,0, destWidth, 480)
      }
      const imageData = this._context.getImageData(0,0, 640, 480)

      this._arkitWorker.postMessage({ type: "process", imagedata: imageData }, [imageData.data.buffer])
    } else {
      this._context.drawImage(this._videoElement, 0, 0, this._videoSize.width, this._videoSize.height, 0,0, this._videoSize.width, this._videoSize.height)
      const imageData = this._context.getImageData(0,0, 640, 480)
      this._arkitWorker.postMessage({ type: "process", imagedata: imageData }, [imageData.data.buffer])
    }
  }

  private _update() : void {
    if (this._world) {
      // for (let i = 0; i < 16; i++) {
      //   this._trackedMatrix.delta[i] = this._world[i] - this._trackedMatrix.interpolated[i]
      //   // if (this._trackedMatrix.delta[i] > 10) {
      //   //   this._trackedMatrix.interpolated[i] = this._world[i]
      //   // }
      //   this._trackedMatrix.interpolated[i] = this._trackedMatrix.interpolated[i] + this._trackedMatrix.delta[i] / this._interpolationFactor
      // }

      const matrix = Matrix.FromArray(this._world)
      let position = new Vector3()
      const rotation = new Quaternion()
      const scale = new Vector3()

      matrix.decompose(scale, rotation, position)

      position = position.multiply(new Vector3(
        0.1,
        0.1,
        0.1)
      )

      if (!this._markerDetected) {
        this._markerDetected = true
        this._controller.markerMesh.position = position
        this._controller.markerMesh.rotationQuaternion = rotation
      } else {
        if (Vector3.Distance(this._controller.markerMesh.position, position) > 10) {
          this._controller.markerMesh.position = position
        }
        this._controller.markerMesh.position = Vector3.Lerp(this._controller.markerMesh.position, position, 1 / this._interpolationFactorPosition)
        this._controller.markerMesh.rotationQuaternion = Quaternion.Slerp(this._controller.markerMesh.rotationQuaternion!, rotation, 1 / this._interpolationFactorRotation)
      }

      this._controller.markerMesh.scaling.x = 1
      this._controller.markerMesh.scaling.y = 1
      this._controller.markerMesh.scaling.z = 1
    } else {
      this._controller.markerMesh.scaling.x = 0
      this._controller.markerMesh.scaling.y = 0
      this._controller.markerMesh.scaling.z = 0
    }
  }

  private _updateVideoElement() : void {

    let ratio = 1

    if (this._mobileDevice) {
      const videoWidth = this._videoElement.videoHeight
      const videoHeight = this._videoElement.videoHeight

      const videoRatio = videoWidth / videoHeight
      const viewRatio = this._viewSize.width / this._viewSize.height

      if (viewRatio > videoRatio) {
        ratio = this._viewSize.width / videoWidth
      } else {
        ratio = this._viewSize.height / videoHeight
      }

      const videoElementSize = {
        width: Math.round(videoWidth * ratio),
        height: Math.round(videoHeight * ratio),
      }

      this._videoElement.style.width = `${videoElementSize.width}px`
      this._videoElement.style.height = `${videoElementSize.height}px`

      const videoMargin = {
        top: (this._viewSize.height - videoElementSize.height) / 2,
        left: (this._viewSize.width - videoElementSize.width) / 2
      }

      this._videoElement.style.marginTop = `${videoMargin.top}px`
      this._videoElement.style.marginLeft = `${videoMargin.left}px`

      this._controller.engine.getRenderingCanvas()!.style.marginTop = `${videoMargin.top}px`
      this._controller.engine.getRenderingCanvas()!.style.marginLeft = `${videoMargin.left}px`

      this._controller.engine.getRenderingCanvas()!.style.width = `${videoElementSize.width}px`
      this._controller.engine.getRenderingCanvas()!.style.height = `${videoElementSize.height}px`

      this._controller.engine.setSize(videoElementSize.width, videoElementSize.height, true)

    } else {
      const videoRatio = this._videoSize.width / this._videoSize.height
      const viewRatio = this._viewSize.width / this._viewSize.height

      if (viewRatio > videoRatio) {
        ratio = this._viewSize.width / this._videoSize.width
      } else {
        ratio = this._viewSize.height / this._videoSize.height
      }

      const videoElementSize = {
        width: Math.round(this._videoSize.width * ratio),
        height: Math.round(this._videoSize.height * ratio),
      }

      this._videoElement.style.width = `${videoElementSize.width}px`
      this._videoElement.style.height = `${videoElementSize.height}px`

      const videoMargin = {
        top: (this._viewSize.height - videoElementSize.height) / 2,
        left: (this._viewSize.width - videoElementSize.width) / 2
      }

      this._videoElement.style.marginTop = `${videoMargin.top}px`
      this._videoElement.style.marginLeft = `${videoMargin.left}px`

      this._controller.engine.getRenderingCanvas()!.style.marginTop = `${videoMargin.top}px`
      this._controller.engine.getRenderingCanvas()!.style.marginLeft = `${videoMargin.left}px`

      this._controller.engine.getRenderingCanvas()!.style.width = `${videoElementSize.width}px`
      this._controller.engine.getRenderingCanvas()!.style.height = `${videoElementSize.height}px`

      this._controller.engine.setSize(videoElementSize.width, videoElementSize.height)
    }

    this._camera.fov = 0.76
  }

  public loadMarker(markerUrl: string) : void {
    this._createWorker(markerUrl)
  }

  public dispose() : void {
    this._animationFrameEnabled = false
    this._arkitWorker.terminate()
    this._videoElement.removeEventListener('loadedmetadata', this._onVideoMetaData)
  }

  public setViewSize(size: { width: number; height: number }) : void {
    this._viewSize = size
    this._updateVideoElement()
  }
}
