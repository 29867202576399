import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {BlurPostProcess, PostProcess, Texture, Vector2} from "@babylonjs/core";

export default class FirePostProcess extends SceneItem {
  private _postProcess: PostProcess
  private _time = 0
  private _fireSpeed = 2
  private _fireLength = 20
  private _screenSize = Vector2.Zero()
  private _mousePosition = Vector2.Zero()

  constructor(props: SceneItemArgs) {
    super(props)

    // const tourTexture = new Texture('/projects/image-effects/textures/tour.png')

    this._postProcess = new PostProcess(
      'fire',
      '/projects/fire/fx/fire',
      ['time', 'screenSize', 'mouse', 'fire_speed', 'fire_len'],
      [],
      1.,
      this.camera
    )

    this._postProcess.onApply = (effect) => {
      this._screenSize.x = this._postProcess.width
      this._screenSize.y = this._postProcess.height

      effect.setFloat('time', this._time)
      effect.setFloat('fire_speed', this._fireSpeed)
      effect.setFloat('fire_len', this._fireLength)
      effect.setVector2('screenSize', this._screenSize)
      effect.setVector2('mouse', this._mousePosition)
      // effect.setTexture('textureSampler', tourTexture)
    }

    this.scene.onBeforeRenderObservable.add(() => {
      this._time += this.sceneController.engine.getDeltaTime()
    })

    // const hblurPostProcess = new BlurPostProcess("Horizontal blur", new Vector2(1.0, 0.0), 12, 1., this.camera)
    // const vblurPostProcess = new BlurPostProcess("Horizontal blur", new Vector2(0.0, 1.0), 12, 1., this.camera)
    //const blurPostProcess = new BlurPostProcess("Horizontal blur", new Vector2(1.0, 0), 2, 1., this.camera)

    window.addEventListener('mousemove', this._onMouseMove)
  }

  private _onMouseMove = (e:MouseEvent) => {
    this._mousePosition.x = e.clientX / window.innerWidth
    this._mousePosition.y = e.clientY / window.innerHeight
  }

  public dispose() : void {
    window.removeEventListener('mousemove', this._onMouseMove)
  }

  setOptions(state: { speed: number; len: number }) : void {
    this._fireSpeed = state.speed
    this._fireLength = 100 - state.len
  }
}
