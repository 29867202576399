
import {Vue} from "vue-class-component";
import ImageEffectsSceneController from "@/components/ImageEffects/classes/ImageEffectsSceneController.class";

export default class ImageEffects extends Vue {
  private _sceneController!: ImageEffectsSceneController

  mounted() : void {
    this._sceneController = new ImageEffectsSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }

  unmounted() : void {
    this._sceneController.dispose()
  }
}
